.show_buy_control {
    display: flex;
    margin-top: 5%;
    justify-content: space-between;
}

.show_buy_control_div {
    display: flex;
    flex-direction: column;
}

.select_btn{
    width: 100%;
    height: 44px;
    border: 1px solid rgb(6, 171, 6);
    color: rgb(6, 171, 6);
    border-radius: 5px;
    min-width: 123px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.3s ease-in-out all;
}

.sold_btn{
    width: 100%;
    height: 44px;
    border: 1px solid rgb(173, 23, 0);
    color: rgb(173, 23, 0);
    border-radius: 5px;
    min-width: 123px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: not-allowed;
    transition: 0.3s ease-in-out all;
}

.select_btn:hover{
    background-color: rgb(6, 171, 6);
    color: #fff !important;
}

.select_btn_text{
    font-weight: 500;
    font-size: 18;
    padding: 0;
    margin: 0;
}

.sold_btn_text{
    font-weight: 500;
    font-size: 18;
}

.category_ticket_div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
}

.category_ticket_title_div {
    display: flex;
    justify-content: flex-end;
    width: 70%;
    min-width: 300px;
    flex-direction: column;
    margin-right: 20px;
    margin-bottom: 10px;
}

.form_category_helpertext {
    margin-left: 0px !important;
    padding-bottom: 15px !important;
}

.price_level_div {
    max-width: 320px !important;
    margin-right: 15px;
}

.swal-footer {
    display: flex;
    justify-content: space-evenly;
}

.swal-button:hover {
    background-color: rgb(255, 255, 255) !important;
    color: orangered !important;
}

.show_ticket_qty {
    background-color: rgb(255, 255, 255);
    color: black;
    border: 1px solid #ccc;
    text-align: center;
    min-height: 50px;
    max-height: 54px;
    vertical-align: middle;
    min-width: 48px;
    font-size: 24px;
    /* padding-top: 6px; */
    margin-top: 16px;
    margin-left: 5px;
    margin-right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.show_ticket_qty_category {
    font-size: 20px;
    background-color: rgb(255, 255, 255);
    color: black;
    border: 1px solid #ccc;
    text-align: center;
    min-height: 30px;
    max-height: 36px;
    padding: 0;
    margin: 0;
    vertical-align: middle;
    min-width: 37px;
}

.inc_dec_btn {
    /* font-size: 24px;
    border: 1px solid #ccc;
    cursor: pointer;
    padding: 14px;
    background-color: #ccc; */
      cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.inc_dec_btn:hover {
    background-color: #474747;
    color: white;
    height: 50px;
    width: 40px;
    font-size: 10px;
}

.inc_dec_btn_category {
    border: 1px solid #ccc;
    cursor: pointer;
    padding: 2px;
     min-height: 35px;
    max-height: 35px;
       min-width: 30px;
    max-width: 36px;
    background-color: #ccc;
    transition: all 0.3s ease-in-out;
}

.inc_dec_btn_category:hover {
    background-color: #474747;
    color: white;
}

.increment_decrement_button {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
}

.increment_decrement_button_category {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    min-width: 123px;
    height: 100%;
    align-items: center;
    justify-content: center;
}

@media (max-width : 980px) {
    .show_buy_control_div {
        width: 100%;
    }
}

@media (max-width : 650px) {
    .show_buy_control_div {
        width: 100%
    }

}

@media (max-width : 460px) {
    .show_buy_control {
        flex-direction: column;
        width: 50%;
        align-items: baseline;
    }

    .category_ticket_title_div {
        min-width: 200px;
    }


    .increment_decrement_button {
        display: flex;
        flex-direction: row-reverse;
        margin-left: 0px;
        margin-top: 20px;
    }

    .price_level_div {
        width: 160% !important;
        max-width: 400px !important;
    }

    .price_level_outer_div {
        margin-bottom: 26px;
    }
}

@media (max-width:420px) {
    .price_level_div {
        width: 120% !important;
        max-width: 400px !important;
    }
}

@media (max-width: 375px) {
     .category_ticket_title_div {
        min-width: 167px;
    }
}

@media (max-width:320px) {
    .formControl_category_select {
        max-width: 300px !important;
    }

     .category_ticket_title_div {
        min-width: 120px;
    }
    .price_level_div {
        width: 100% !important;
        max-width: 400px !important;
    }
}