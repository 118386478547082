.login_container {
    width: 45%;
    margin-top: 5%;
    text-align: center;
}

.login_form {
    width: 100%;
}

.form_input_div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 5%;
}

.login_input_tag {
    margin: auto;
    width: 90%;
    height: 2.5rem;
}

.form_input_submit_div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-top: 5%;
    text-align: right;
}



.form_input_btn {
    border: none;
    outline: none;
    background-color: transparent;
    border: 1px solid #ff7530;
    padding: 3%;
    font-size: 1.2rem;
    color: #ff7530;
    margin-right: 5%;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    margin-bottom: 16px;
    min-width: 180px;
}

.form_input_btn:hover {
    background-color: #ff7530;
    color: white;
}

.delete_input_btn {
    margin-top: 15px;
    border: none;
    outline: none;
    background-color: transparent;
    border: 1px solid #ff7530;
    padding: 3%;
    font-size: 1.2rem;
    color: #ff7530;
    margin-right: 5%;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    margin-bottom: 16px;
    width: 200px;
}

.delete_input_btn:hover {
    background-color: #ff7530;
    color: white;
}

.form_input_checkbox_div {
    width: 53%;
}

.form_input_checkbox {
    margin-right: 5%;
    color: #ff7530;
}

.forget_password_link {
    color: #ff7530;
    margin-top: 4%;
    cursor: pointer;
    width: max-content;
}

@media (max-width: 800px) {
    .login_container {
        width: 100%;
        margin-top: 5%;
    }

}

@media (max-width:500px) {
    .form_input_btn {
        width: 150px;
    }
}

@media (max-width:395px) {
    .form_input_btn {
        min-width: 100px;
    }
}

@media (max-width:390px) {
    .form_input_btn {
        min-width: 120px;
    }
}