.my_profile_heading{
    font-size:4rem;
    color: white;
    margin-top: -50px;
}

.register_btn_helper_text{
    color: black;
    font-size: 10px;
    font-weight: 800;
    display: none;
}

.form_input_btn_same_size{
     border:  none;
    outline: none;
   background-color: transparent;
   border : 1px solid #ff7530;
   padding: 3%;
   font-size: 1.2rem;
   color: #ff7530;
   margin-right: 5%;
   cursor: pointer;
   transition: all 0.3s ease-in-out;
   margin-bottom: 16px;
   width: 200px;
}

.form_input_btn_same_size:hover{
    background-color: #ff7530;
    color: white;
}

.saved_cards_btn{
    color: #ff7530 !important;
}
.saved_cards_btn:hover{
    color: white !important;
}

#logoutBTN{
    display: none;
}

.my_profile_div_sm{
    max-width: 480px;
    min-width: 280px;
    width: 100%;
    min-height: 300px;
    margin: auto;
    /* border: 1px solid #ccc; */
    margin-top: 50px;
}
.profileDetailTag{
    width: 90%;
    padding-left: 5%;
    padding-right: 5%;
    margin: auto;
    background-color: #fff;
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-size: 16px;
    text-align: left;
    border-radius: 5px;
    margin-top: 10px;
    font-weight: 600;
    margin-bottom: 20px;
}
.profileDetailTag_heading{
    font-size: 18px;

}

.change_password_div span{
    font-weight: 600;
    font-size: 20px;
}.change_password_bottom_stroke{
    min-width: 300px;
    width: 100%;
    background-color: rgba(146, 146, 146, 0.8);
    height: 1.5px;
    margin-top: 5px;
}

@media (max-width:900px){
    .my_profile_div_sm{
        min-height: 700px;
    }
    #logoutBTN{
        display: block;
    }
    .register_btn_helper_text{
        display: block;
    }
    
}

@media (max-width:480px){
    
.my_profile_div_sm{
    max-width: 480px;
    min-width: 280px;
    width: 80%;
    min-height: 400px;
    margin: auto;
    /* border: 1px solid #ccc; */
    margin-top: 50px;
}
}

