.privacy_policy_div{
    width: 80%;
    margin: auto;
    text-align: justify;
    margin-top: 50px;
    margin-bottom: 50px;
}

.linksforrefund{
    color: #ff7530;
}
.privacy_heading{
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 15px;
}
.privacy_details{
    font-size: 14px;
    margin-bottom: 15px;
}

.privacy_subheading{
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 15px;
}

.privacy_details li {
    margin-top: 15px;
    margin-bottom: 15px;
}