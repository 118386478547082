.primary_button {
  text-decoration: none;
  width: 199px;
  display: flex;
  justify-content: center;
  padding-top: 15px;
  padding-bottom: 15px;
  font-size:20px;
  font-weight: 600;
  color: #fff;
  background: #ff7530;
  border-radius: 10px;
}

.ptp_navlink {
  margin-top: 5%;
  width: 50%;
}

.out_of_stock_button{
  font-size: 18px;
  font-weight: 700 !important;
  vertical-align: middle;
  animation: all 1s ease-in-out;
  background-color: rgb(243, 202, 202);
  color: red;
  border: 1.5px solid red;
  border-radius: 4px;
  margin-top: 2%;
  height: 3rem;
  width: 100%;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  animation: all 1s ease-in-out;
}

.out_of_stock_button:hover {
  background-color: red;
  color: white;
}

.primary_button:hover {
  animation: linearColour 2s infinite;
}

.secondary_button {
   width: 199px;
  display: flex;
  justify-content: center;
  padding-top: 15px;
  padding-bottom: 15px;
  font-size:20px;
  background: #fff;
  text-decoration: none;
  color: #ff7530;
  font-weight: 600;
  border: 2px solid #ff7530;
  border-radius: 10px;
}

.secondary_button:hover {
  background-color: #ff7530;
  color: white;
}

.proceed_to_payment_button {
  font-size: 18px;
  font-weight: 700 !important;
  vertical-align: middle;
  animation: all 1s ease-in-out;
  background-color: #fff;
  color: #ff7530;
  border: 1.5px solid #ff7530;
  border-radius: 4px;
  margin-top: 2%;
  height: 3rem;
  width: 100%;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  animation: all 1s ease-in-out;
}
.proceed_to_payment_button:hover {
  background-color: #ff7530;
  color: white;
}

@keyframes mymove {
  from {
    left: 1px;
    opacity: 0;
  }
  to {
    left: 10px;
    opacity: 1;
  }
}

@media (max-width: 980px) {
  .ptp_navlink {
    width: 70%;
  }
  .proceed_to_payment_button {
    font-size: 16px;
    border: 2px solid #ff7530;
  }
}

@media (max-width: 650px) {
  .proceed_to_payment_button {
    font-size: 16px;
  }
  .primary_button {
    text-decoration: none;
    width: 250px;
    display: flex;
    justify-content: center;
    padding-top: 15px;
    padding-bottom: 15px;
    font-size:20px;
    font-weight: 600;
    color: #fff;
    background: #ff7530;
    margin-bottom: 16px;
  
  }  
  .primary_button:hover {
    animation: linearColour 2s infinite;
  }
  
  .secondary_button {
     width: 250px;
    display: flex;
    justify-content: center;
    padding-top: 15px;
    padding-bottom: 15px;
    font-size:20px;
    background: #fff;
    text-decoration: none;
    color: #ff7530;
    font-weight: 600;
    border: 2px solid #ff7530;
    margin-bottom: 20px;
  }
}


@media (max-width:428px) {
    .proceed_to_payment_button{
      margin-top: 20px;
    }
}

@media (max-width:414px) {
    .proceed_to_payment_button{
      margin-top: 5px;
    }
}

@media (max-width: 320px) {
  .proceed_to_payment_button {
    font-size: 14px;
    border: 2px solid #ff7530;
  }

  .primary_button {
    width: 200px;
    padding-top: 15px;
    padding-bottom: 15px;
    font-size:18px;
  
  }  
  .primary_button:hover {
    animation: linearColour 2s infinite;
  }
  
  .secondary_button {
    width: 200px;
    padding-top: 15px;
    padding-bottom: 15px;
    font-size:18px;
  }


}
