.ticket_pop_up_backdrop {
  display: none;
}


.logo_ticket_popup_div{
  margin-top: 50px;
  border-top: 1px dashed black;
  border-bottom-left-radius: 19px;
  border-bottom-right-radius: 19px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.close_fullscreen{
  position: absolute;
  top: 0;
  z-index: 999;
  left: 0;
  margin-left: 5px;
  background-color: white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 10px black;
}

.logo_ticket_popup{
  width: 80%;
}

.grid_ticket_popup_mobile{
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(2,1fr);
  gap: 5px;
  row-gap: 15px;
  padding: 9px;
  width: 98%;
  height: 20%;
}

.grid_ticket_popup_mobile_title{
  color: #00000054;
  font-size: 15px;
  text-align: left;
}

.grid_ticket_popup_mobile_desc{
  color: black;
  font-weight: 700;
  text-align: left;
  padding-right: 2px;
}

@media (max-width: 750px) {
  .ticket_pop_up_backdrop {
    display: block;
    position: fixed;
    background: #00000050;
    width: 100%;
    bottom: -200vh;
    left: 0;
    z-index: 899;
    transition: all 0.5s ease-in-out;
    margin: auto;
  }
  .ticket_popup {
    background-color: white;
    width: 90%;
    max-width: 400px;
    margin: auto;
    top: 0;
    /* max-height: 700px; */
    border-radius: 19px;
    /* overflow-y: scroll; */
  }


.ticket_popup_img_div{
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-size: cover;
  height: 100%;
  width: 100%;
  max-height: 300px;
  border-top-left-radius: 19px;
  border-top-right-radius: 19px;
}

  .ticket_pop_up_backgroundimg {
  width: 100%;
  object-fit: contain;
  height: 100%;
  margin: auto;
  backdrop-filter: blur(10px);
  border-top-left-radius: 19px;
  border-top-right-radius: 19px;
  }
  .ticket_popup_qr {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
      top: 0;
      width: 120px;
      height: 120px;
      margin: auto;
      min-width: 123px;
      min-height: 123px;
      margin-top: -20%;     
    box-shadow: 0 0 10px rgb(12, 12, 12);
    border-radius: 15%;
  }
  .tickets_table_mobile {
    padding-top: 3%;
    text-align: left;
    width: 100%;
    margin-left: 5%;
    padding-bottom: 10%;
    overflow: hidden;
  }
}


@media (max-width: 500px) {
  .ticket_pop_up_backdrop {
    max-width: 500px;
    height: 100vh;
  }


.grid_ticket_popup_mobile_title{
  color: #00000054;
  font-size: 14px;
  text-align: left;
}

.grid_ticket_popup_mobile_desc{
  color: black;
  font-weight: 700;
  font-size: 16px;
  text-align: left;
}

  .ticket_popup {
    width: 99%;
    height: 98vh;
    max-height: 700px;
    margin-top: 15%;
  }
  .table_info{
    font-size: 0.9rem;
  }

  .grid_ticket_popup_mobile{
  margin-top: 15px;
  display: grid;
  grid-template-columns: repeat(2,1fr);
  row-gap: 5px;
  padding-left: 8px;
  padding-right: 8px;
  width: 100%;
}
.logo_ticket_popup{
  margin-top: 25px;
}

   .ticket_pop_up_backgroundimg {
  width: 100%;
  object-fit: contain;
  margin: auto;
  backdrop-filter: blur(10px);
  }
}


@media (max-width: 380px) {

   .ticket_popup {
    width: 98%;
    height: 100vh;
    max-height: 700px;
    margin-top: 0%;
  }

}




@media (max-width: 320px) {

  .table_info{
    font-size: 0.9rem;
  }

  .grid_ticket_popup_mobile{
  margin-top: 5px;
}
.logo_ticket_popup{
  margin-top: 25px;
}


.ticket_popup_img_div{
  max-height: 200px;
}


.grid_ticket_popup_mobile_title{
  font-size: 12px;
}

.grid_ticket_popup_mobile_desc{
  font-size: 14px;
}

}