.get-wrp {
    padding: 150px 0;
    position: relative;
    background: #080808;
}

.get-wrp h1 {
    font-size: 128px;
    font-weight: bold;
    color: #ff7530;
    margin: 0 auto 0;
    line-height: 128px;
    text-align: center;
    padding: 0 0 40px;
    position: relative;
}

.get-wrp h1:after {
    position: absolute;
    content: '';
    width: 24px;
    height: 4px;
    background: #B948FF;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.get-wrp h1 span {
    display: table;
    position: relative;
    overflow: hidden;
    margin: 0 auto 5px;
}
/* 
.get-wrp.highlight h1 span::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: #F58D53;
    animation: a-ltr-before 2s cubic-bezier(.77,0,.18,1) forwards;
    -webkit-animation: a-ltr-before 2s cubic-bezier(.77,0,.18,1) forwards;
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
} 

.get-wrp h1 span:nth-of-type(1)::before {
    animation-delay: 1s;
}

.get-wrp h1 span:nth-of-type(2)::before {
    animation-delay: 1.5s;
}

.get-wrp h1 span:nth-of-type(3)::before {
    animation-delay: 2s;
}

.get-wrp h1 span:nth-of-type(1)::before,
.get-wrp h1 span:nth-of-type(3)::after,
.get-wrp h1 span:nth-of-type(2)::after {
    animation-delay: 1s;
}

@keyframes a-ltr-after {
    0% { 
        transform: translateX(-100%); 
        -webkit-transform: translateX(-100%); 
        -moz-transform: translateX(-100%); 
        -ms-transform: translateX(-100%); 
        -o-transform: translateX(-100%); 
    }
    100% { 
        transform: translateX(101%); 
        -webkit-transform: translateX(101%); 
        -moz-transform: translateX(101%); 
        -ms-transform: translateX(101%); 
        -o-transform: translateX(101%);
    }
}

@keyframes a-ltr-before {
    0% {
        transform: translateX(0);
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
    }
    100% {
        transform: translateX(200%);
        -webkit-transform: translateX(200%);
        -moz-transform: translateX(200%);
        -ms-transform: translateX(200%);
        -o-transform: translateX(200%);
    }
} */

/* .get-wrp.highlight h1 span::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: #f58d53;
    transform: translateX(0);
    animation: none;
  } */
  
  .get-wrp.animate h1 span::before {
    animation: a-ltr-before 2s cubic-bezier(.77, 0, .18, 1) forwards;
  }

  
.get-wrp h1 span:nth-of-type(1)::before {
    animation-delay: 1s;
}

.get-wrp h1 span:nth-of-type(2)::before {
    animation-delay: 1.5s;
}

.get-wrp h1 span:nth-of-type(3)::before {
    animation-delay: 2s;
}

.get-wrp h1 span:nth-of-type(1)::before,
.get-wrp h1 span:nth-of-type(3)::after,
.get-wrp h1 span:nth-of-type(2)::after {
    animation-delay: 1s;
}
  
  @keyframes a-ltr-before {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(200%);
    }
  }

@media(min-width:1201px) and (max-width:1350px) {
    .get-wrp{padding: 100px 0;}
	.get-wrp h1{font-size: 64px;line-height:78px;padding: 0 0 20px;display: table;}
	.get-wrp h1 span{margin: 0 0 8px; display: block;}
}

@media(min-width:1025px) and (max-width:1200px) {
    .get-wrp{padding: 70px 0;}
	.get-wrp h1{font-size: 58px;line-height:68px;padding: 0 0 20px;display: table;}
	.get-wrp h1 span{margin: 0 auto 8px;}
}

@media(min-width:992px) and (max-width:1024px) {
    .get-wrp{padding: 70px 0;}
	.get-wrp h1{font-size: 58px;line-height:68px;padding: 0 0 20px;display: table;}
	.get-wrp h1 span{margin: 0 0 8px;}
}

@media(min-width:768px) and (max-width:991px) {
    .get-wrp{padding: 50px 0;}
	.get-wrp h1{font-size: 48px;line-height:50px;padding: 0 0 20px;display: table;}
	.get-wrp h1 span{margin: 0 0 8px; display: block;}
}

@media(max-width:767px) {
    .get-wrp{padding: 50px 0;}
	.get-wrp h1{font-size: 48px;line-height:50px;padding: 0 0 20px;}
}