.shows_div {
  width: 80%;
  margin: auto;
  height: auto;
  padding-top: 2%;
  padding-bottom: 8%;

}
.shows_div_grid {
  padding: 1%;
  width: 100%;
  margin: auto;
  margin-top: 2%;
  margin-bottom: 5%;
  max-width: 1000px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 5px;
  padding-bottom: 5%;
  row-gap: 15px !important;
}

.searchBarDiv{
  width: 30%;
  display: flex;
  align-items: center;
  border: 1px solid rgb(180, 180, 180);
  padding: 2px;
}

.searchBar{
  width: 80%;
  height: 2.5rem;
  font-size: 14px;
  text-align: left;
  border: none;
}
.searchBar:focus{
 outline: none;
}


.shows_sorting_controller {
  display: flex;
  max-width: 1000px;
  margin: auto;
  width: 98%;
  justify-content: space-between;
  flex-wrap: wrap;
  text-align: justify;
  align-items: center;
}

.sortingMenu1{
  min-width: 240px;
  margin-right: 5px;
}
.sortingMenu2{
  min-width: 240px;
  margin-left: 5px;
}


.searching_div_flex{
  display: flex;
  flex-direction:  row;
  justify-content: space-evenly;
}

.info_div {
  box-shadow: 0 0 70px #ccc !important;
}

.pageHeader {
  text-align: center;
}
.pageHeader h1 {
  text-transform: uppercase;
  font-size: 80px;
  line-height: 1.2em;
}
.items_div {
  width: 80%;
  margin: auto;
  margin-bottom: 5%;
  padding-bottom: 5%;
}

.more_div {
  margin: auto;
  width: 100%;
  display: flex;
  justify-content: center;
}


@media (max-width: 1030px) {
  .shows_div_grid {
    grid-gap: 20px;
  }
}


@media (max-width: 1000px) {

  .shows_sorting_controller h1 {
   font-size: 1rem;
  }
  .shows_sorting_controller p{
    font-size: 0.8rem;
  }
  .sortingMenu{
    width: 30%;
    margin-top: 2%;
  }

}

@media (max-width: 900px) {
    .backgroundDiv2 {
        background-position-x: center;
  }
  .shows_sorting_controller h1 {
    font-size: 1.2rem;
   }
   .shows_sorting_controller p{
     font-size: 1rem;
   }
   .sortingMenu{
     width: 100%;
   }
   .filter_search{
     margin-bottom: 2%;
   }

   .searchBarDiv{
    width: 100%;
    display: flex;
    align-items: center;
    border: 1px solid rgb(180, 180, 180);
    padding: 2px;
  }
  .pageHeader h1 {
    font-size: 40px;
    line-height: 1.2em;
  }
  .shows_div_grid {
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 20px;
  }
  .searching_div_flex{
    flex-direction: column;
    width: 100%;
  }
  .sortingMenu1{
    min-width: 240px;
    margin-right: 0px;

  }
  .sortingMenu2{
    min-width: 240px;
    margin-left: 0px;
    margin-top: 15px;
  }
}

@media (max-width: 640px) {
  .shows_div_grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .shows_div {
    width: 92%;
    margin: auto;
  }
  .backgroundDiv2 {
    background-size: contain;
    background-attachment: fixed;
    background-repeat: no-repeat;
  }
  .secondBackgroundDiv2 h1 {
    margin-top: -9%;
    padding-bottom: 0%;
  }
  .shows_div_grid {
    grid-template-columns: repeat(2, 1fr);
  grid-gap: 8px;
  row-gap: 25px !important;

  }
  .shows_sorting_controller {
    display: flex;
    flex-direction: column;
  }
  .shows_sorting_controller p {
    margin-bottom: 4%;
  }
  .shows_div {
    padding-bottom: 10%;
  }
}

