.banner-wrapper {
    position: relative;
    width: 100%;
    height: 100vh;
}

.videofrem {
    width: 100%;
    height: 100vh;
    position: relative;
}

.videofrem video {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: bottom;
}

.videofrem:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: -moz-linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%);
    background: -webkit-linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%);
    background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%);
}

.banner-center {
    position: absolute;
    top:50%;
    left: 0;
    right: 0;
    transform: translateY(-45%);
    -webkit-transform: translateY(-45%);
    -moz-transform: translateY(-45%);
    -ms-transform: translateY(-45%);
    -o-transform: translateY(-45%);
}

.banner-data {
    max-width: 820px;
    margin: 0 auto;
}

.banner-data h1 {
    font-size: 96px;
    font-weight: bold;
    color: #fff;
    line-height: 110px;
    padding: 0 0 40px;
    margin: 0 0 40px;
    position: relative;
}

.banner-data h1:after {
    position: absolute;
    content: '';
    width: 24px;
    height: 4px;
    background: #B948FF;
    bottom: 0;
    left: 0;
}

.banner-data p {
    font-size: 18px;
    font-weight: normal;
    max-width: 650px;
    color: #fff;
    line-height: 32px;
    margin: 0 0 40px;
}

/* .partner-slider {
    padding: 108px 0 0;
    overflow: hidden;
    background: #080808;
}

.partner-box {
    white-space: nowrap;
    width:250%;
    height:98px;
    background: url('../../images/slider-partners.png') repeat-x;
    background-size: 50%;
    animation: scroll-anim 10s linear infinite;
    -webkit-animation: scroll-anim 10s linear infinite;
} */

/* @keyframes scroll-anim {
    100% {
        background-position: -100% 0;
    }
} */

@media(min-width:1201px) and (max-width:1350px) {
    .banner-data h1{font-size: 60px;line-height: 60px;padding: 0 0 20px;margin: 0 0 20px;}
	.banner-data p{font-size: 18px;line-height: 30px;}
}

@media(min-width:1025px) and (max-width:1200px) {
    .banner-data h1{font-size: 50px;line-height: 60px;padding: 0 0 20px;margin: 0 0 20px;}
	.banner-data p{font-size: 16px;line-height: 25px;}
}

@media(min-width:992px) and (max-width:1024px) {
    .banner-data h1{font-size: 50px;line-height: 60px;padding: 0 0 20px;margin: 0 0 20px;}
	.banner-data p{font-size: 16px;line-height: 25px;}
}

@media(min-width:768px) and (max-width:991px) {
    .banner-data h1{font-size: 50px;line-height: 60px;padding: 0 0 20px;margin: 0 0 20px;}
	.banner-data p{font-size: 16px;line-height: 25px;}
}

@media(max-width:767px) {
    .banner-data h1{font-size: 30px;line-height: 40px;padding: 0 0 10px;margin: 0 0 20px;}
	.banner-data p{font-size: 14px;line-height: 24px;margin: 0 0 25px;}
}