.product_table{
    width: 80%;
    max-width: 1000px;
    margin: auto;
    font-size: 1rem;
    border: 1px solid rgb(218, 218, 218);
}

.paymentDivH2{
    font-size: 2.5rem;
}


.product_table thead td:first-child{
    text-align: left;
    font-weight: 700;
}
.product_table  td{
    padding: 1%;
    max-width: 500px;
}

.product_table thead tr:first-child td{
    border-bottom: .8px solid rgb(185, 185, 185);
    vertical-align: middle;
    align-items: center;
}

.product_table tr td{
    border-bottom: .8px solid rgb(185, 185, 185);
    vertical-align: middle;
    align-items: center;
}

.product_table thead td:last-child{
    text-align: left;
    font-weight: 700;
}
.product_table tbody td:first-child{
    text-align: left;
}
.product_table tbody td:last-child{
    text-align: left;
}

.product_table tr:last-child td{
    border-bottom: none;
    vertical-align: middle;
    align-items: center;
}

.table_total{
    font-weight: 700;
    font-size: 1.1rem;
}

.checkout_payment_div{
    width: 80%;
    max-width: 1000px;
    margin: auto;
    text-align: center;
    margin-top: 2%;
    background-color: rgba(255, 172, 17, 0.247);
}

.paypalPaymentDiv{
    margin: auto;
    width: 100%;
    max-width: 1000px;
}

.paypalButton{
    margin: auto;
    max-width: 150px ;
}

@media (max-width: 774px) {
    .product_table  td{
        padding: 1%;
        width: 74%;
    }
}

@media (max-width:500px){
    .product_table{
        width: 90%;
        max-width: 1000px;
        margin: auto;
        font-size: .7rem;
    }
    .checkout_payment_div{
        width: 95%;
    }
    .product_table tbody td:last-child{
        text-align: center;
    }
    .product_table thead td:last-child{
        text-align: center;
        font-weight: 700;
    }
}



