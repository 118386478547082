.easy-step-wrp {
    padding: 150px 0;
    background: #fff;
    position: relative;
    overflow: hidden;
}

.easy-step-wrp .row {
    align-items: center;
}

.easy-title-box h2 {
    font-size: 96px;
    font-weight: bold;
    color: #000;
    line-height: 110px;
    margin: 0 0 40px;
    padding: 0 0 40px;
    position: relative;
}

.easy-title-box h2:after {
    position: absolute;
    content: '';
    width: 24px;
    height: 4px;
    background: #B948FF;
    bottom: 0;
    left: 0;
}

.easy-title-box p {
    font-size: 18px;
    font-weight: normal;
    color: #000;
    max-width: 550px;
    line-height: 30px;
    margin: 0 0 40px;
}

.easy-title-box .btn-main span {
    border-right: 3px dotted #fff;
    transition: .4s linear;
    -webkit-transition: .4s linear;
    -moz-transition: .4s linear;
    -ms-transition: .4s linear;
    -o-transition: .4s linear;
}

.easy-title-box .btn-main:hover svg {
    color: #000;
}

.easy-title-box .btn-main:after {
    background: #fff;
}

.steps-list ul li {
    display: flex;
    width: 100%;
    position: relative;
    padding: 30px;
    margin: 0 0 80px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.20);
}

.steps-list ul li:last-child {
    margin: 0;
}

.steps-list ul li:after {
    position: absolute;
    content: '';
    width: 100%;
    height: 1px;
    right: -100%;
    bottom: -1px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.20);
}

.step-num {
   min-width: 125px;
   margin-right: 35px;
}

.step-num img {
    width: 100%;
}

.step-data h3 {
    font-size: 48px;
    font-weight: 600;
    color: #000;
    margin: 0 0 20px;
}

.step-data p {
    margin: 0;
    font-size: 18px;
    font-weight: normal;
    color: #000;
    line-height: 30px;
    max-width: 290px;
}

@media(min-width:1201px) and (max-width:1350px) {
    .easy-step-wrp{padding:100px 0;}
	.easy-title-box h2{font-size: 62px;line-height: 78px;margin: 0 0 20px;padding: 0 0 20px;}
	.easy-title-box p{font-size: 18px;line-height: 30px;}
	.steps-list ul li{padding: 25px 15px;margin: 0px;}
	.step-num{min-width: 60px;margin-right: 20px;}
	.step-data h3{font-size: 32px;margin: 0 0 15px;}
	.step-data p{font-size: 18px;line-height:30px;}
}

@media(min-width:1025px) and (max-width:1200px) {
    .easy-step-wrp{padding:70px 0;}
	.easy-title-box h2{font-size: 48px;line-height: 58px;margin: 0 0 20px;padding: 0 0 20px;}
	.easy-title-box p{font-size: 16px;line-height: 24px;}
	.steps-list ul li{padding: 25px 15px;margin: 0px;}
	.step-num{min-width: 70px;margin-right: 20px;}
	.step-data h3{font-size: 28px;margin: 0 0 10px;}
	.step-data p{font-size: 14px;line-height: 22px;}
}

@media(min-width:992px) and (max-width:1024px) {
    .easy-step-wrp{padding:70px 0;}
	.easy-title-box h2{font-size: 48px;line-height: 58px;margin: 0 0 20px;padding: 0 0 20px;}
	.easy-title-box p{font-size: 16px;line-height: 24px;}
	.steps-list ul li{padding: 25px 15px;margin: 0px;}
	.step-num{min-width: 70px;margin-right: 20px;}
	.step-data h3{font-size: 28px;margin: 0 0 10px;}
	.step-data p{font-size: 14px;line-height: 22px;}
}

@media(min-width:768px) and (max-width:991px) {
    .easy-step-wrp{padding:50px 0;}
	.easy-title-box h2{font-size: 48px;line-height: 58px;margin: 0 0 20px;padding: 0 0 20px;}
	.easy-title-box p{font-size: 16px;line-height: 24px;}
	.steps-list ul li{padding: 25px 15px;margin: 0px;}
	.step-num{min-width: 70px;margin-right: 20px;}
	.step-data h3{font-size: 28px;margin: 0 0 10px;}
	.step-data p{font-size: 14px;line-height: 22px;}
}

@media(max-width:767px) {
    .easy-step-wrp{padding: 50px 0;}
	.easy-title-box h2{font-size: 42px;line-height: 50px;text-align: center;padding: 0 0 20px;margin: 0 0 25px;}
	.easy-title-box h2:after{left: 0;right: 0;margin: 0 auto;}
	.easy-title-box p{font-size: 15px;line-height: 22px;text-align: center;margin: 0 auto 25px;}
	.easy-title-box .btn-main{margin: 0 auto 45px;justify-content: center;}
	.steps-list ul li{padding: 25px 15px;margin: 0px;}
	.step-num{min-width: 80px;margin-right: 25px;}
	.step-data h3{font-size: 32px;margin: 0 0 10px;}
	.step-data p{font-size: 16px;line-height: 25px;}
}