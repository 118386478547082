.ticket_page_div{
    width: 70%;
    max-width: 1000px;
    margin: auto;
    border: 1px solid #ccc;
    min-height: 75vh;
    text-align: center;
}
.ticket_page_div_nav{
    width: 70%;
    max-width: 1000px;
    margin: auto;
    margin-top: 3%;
    text-align: center;
}

.no_tickets_div{
    margin: auto;
    margin-top: 15%;
}

.purchase_now_btn{
    background-color: #ff7530;
    color: white;
    padding: 1%;
    padding-left: 1%;
    padding-right: 1%;
    font-size: 1.2rem;
    border: none;
    outline: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 2%;
}
.purchase_now_btn:hover{
    background-color: #f85706;
}

.tickets_sub_div{
    margin-top: 0%;
    margin-bottom: 15px;
}
@media (max-width:900px){
    .ticket_page_div{
        width: 90%;
        max-width: 1000px;
        margin: auto;
        min-height: 75vh;
        text-align: center;
    }
    .ticket_page_div_nav{
        width: 90%;
    }
}
