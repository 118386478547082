.partner-wrp {
    background-color: #080808;
    overflow: hidden;
    padding: 108px 0 0;
}

.partner-wrp .slick-track {
    display: flex;
    align-items: center;
}

.partner-wrp .slick-slide {
    margin: 0 40px;
}