.stripe_btn {
    width: 100%;
    height: 52px;
    background-color: #635BFF;
    border-radius: 5px;
    outline: none;
    border: none;
    color: #fff;
    font-size: 18px;
    cursor: pointer;
    font-weight: 600;
}

.stripe_logo {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 400px;
    height: 50px;
    margin-bottom: 20px;
}