.tickets_card {
  width: 95%;
  display: flex;
  justify-content: space-between;
  margin: auto;
  margin-top: 2%;
  box-shadow: 0 0 10px rgb(180, 180, 180);
  border-radius: 13px;
  padding: 1%;
  align-items: center;
}

.view_seats{
  padding: 2px;
  font-size: 11px;
  font-weight: 600;
  border-radius: 6px;
  cursor: pointer;
  outline: none;
  border: 1px solid #ccc;
  color: blue;
  transition: all 0.2s ease-in-out;
}

.seats_table_row{
  border-bottom: 2px dotted #ccc;
  margin-bottom: 2px;
}

.seats_table_row_undotted{
  margin-bottom: 2px;
}

.view_seats:hover{
  background-color: #ccc;
}

.tickets_show_details {
  display: none;
  font-size: 11px;
}
.ticket_thumbnail {
  min-width: 220px;
  width: 100%;
  height: 100%;
  min-height: 220px;
  border-radius: 13px;
  object-fit: contain;
  margin: auto;
  backdrop-filter: blur(10px);
}
.tickets_img_div{
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 10px;
  background-size: cover;
  height: 100%;
  width: 100%;
  max-height: 220px;
  max-width: 220px;
}

.ticket_details {
  width: 50%;
  text-align: left;
  vertical-align: middle;
  height: 30%;
}
.ticket_show_title {
  font-size: 1.5rem;
  font-weight: 700;
}

.ticket_qr_code{
  padding : 1%;
  width: 140px;
  height: 140px;
  box-shadow: 0 0 10px rgb(180, 180, 180);
  border-radius: 13px;
  
}

.tickets_table {
  padding-top: 3%;
  text-align: left;
  width: 100%;
}
.table_column{
  font-weight: 700;
  font-size: .9rem;
  color: grey;
}
.table_column{
  width: 20%;
  padding-top: 1%;
}

.table_colon{
  width: 10%;
  padding-top: 1%;
}

.table_info {
  font-weight: 700;
  text-align: left;
  width: 60%;
  padding-top: 1%;
}
@media (max-width: 1005px) {
  .ticket_show_title {
    font-size: 1.2rem;
    font-weight: 700;
  }
  .tickets_table {
    padding-top: 3%;
    text-align: left;
    width: 90%;
    margin-left: 10%;
  }
  .table_column{
    font-size:  .9rem;
  }
  
  .tickets_card {
    width: 95%;
    overflow: hidden;
  }
  .ticket_quantity_popup_table td {
    width: 45%;
    padding-top: 1%;
  }

  .table_info {
    font-weight: 700;
    text-align: left;
  }
}

@media (max-width: 750px) {
  .tickets_table {
    display: none;
  }
  .tickets_show_details {
    color: orange;
    display: block;
  }
  .ticket_thumbnail {
    max-width: 200px;
    max-height: 200px;
    width: 20%;
    min-width: 200px;
    min-height: 200px;
    object-fit: cover;
  }
}

@media (max-width: 500px) {
  .ticket_show_title {
    font-size: 1.6rem;
    font-weight: 700;
    text-align: left;
  }
  .tickets_table {
    display: none;
  }
  .ticket_thumbnail {
    min-width: 100px;
  width: 100%;
  height: 100%;
  min-height: 100px;
  border-radius: 13px;
  object-fit: contain;
  margin: auto;
  backdrop-filter: blur(10px);
  }
  .tickets_show_details {
    font-size: 14px;
    color: orange;
    display: block;
    text-align: left;
  }
  .ticket_details {
    width: 70%;
    margin-left: 15px;
  }
  .ticket_qr_code {
    display: none;
  }
  .tickets_img_div{
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 10px;
  background-size: cover;
  height: 100%;
  width: 100%;
  max-height: 100px;
  max-width: 100px;
}
}

@media (max-width: 414px) {
  .ticket_show_title {
    font-size: 1.3rem;
    margin-right: 5px;
  }
}

@media (max-width: 390px) {
  .ticket_show_title {
    font-size: 1.2rem;
  }
}



@media (max-width: 360px) {
  .ticket_show_title {
    font-size: 1.1rem;
  }
  .tickets_table {
    display: none;
  }
  .ticket_thumbnail {
    max-width: 100.77px;
    max-height: 100.77px;
    min-width: 100.77px;
    min-height: 100.77px;
    object-fit: cover;
  }
  .tickets_show_details {
    font-size: 14px;
    color: orange;
    display: block;
    text-align: left;
  }
  .ticket_details {
    width: 60%;
  }
  .ticket_qr_code {
    display: none;
  }
}
