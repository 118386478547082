.back_to_top_div{
    position: fixed;
    top : -100vh;
    margin-bottom: 10%;
    right: 0;
    z-index: 9999;
    transition: 1s all ease-in-out;
    box-shadow: 0 0 10px #ccc;
}

.back_to_top_btn{
    background-color: transparent;
    background: rgba(17, 17, 17, 0.541);
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 4px );
    -webkit-backdrop-filter: blur( 4px );
    border: 1px solid rgba( 255, 255, 255, 0.18 );
    width: 3rem;
    height: 3rem;
    cursor: pointer;
}


@media (max-width: 900px) {
    .back_to_top_div{
        margin-bottom: 20%;
    }
}

@media (max-width: 429px) {
    .back_to_top_div{
        margin-bottom: 40%;
    }
}