.verification_box_success{
    background-color: #A3DA8D;
    min-width: 200px;
    min-height: 100px;
    max-width: 500px;
    max-height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
}
.verification_box_success p {
color: #1d5202;
font-weight: 900;
text-align: center;
}

.verification_box_error{
    background-color: #FF8E00;
    min-width: 200px;
    min-height: 100px;
    max-width: 500px;
    max-height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
}
.verification_box_error p {
color: #524b02;
font-weight: 900;
text-align: center;
}


.verification_box_loading{
    background-color: #b49dcd;
    min-width: 200px;
    min-height: 100px;
    max-width: 500px;
    max-height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
}


.verification_to_login_btn{
    border: 2px solid orange;
    color: orange !important;
    width: 150px;
    height: 40px;
    margin: auto;
    margin-top: 50px;
    text-align: center;
    font-weight: 900;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
     background-color: orange;
}

/* .verification_to_login_btn:hover{
    background-color: orange;
    color: #fff !important;
} */

@media (max-width:500px){
    .verification_box_success{
   width: 80%;
}
   .verification_box_error{
   width: 80%;
}

   .verification_box_loading{
   width: 80%;
}


}