.order_success_list{

    width: 80%;
    max-width: 1000px;
    margin: auto;
    justify-content: space-between;
}
.order_success_list p{
   font-weight: bold;
}

.qr_code_div_for_success_payment{
    margin: auto;
    display: flex;
    justify-content: center;
    padding-bottom: 15px;
    width: 300px;
    margin-top: 2%;
}
