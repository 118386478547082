 .widget_horizontal_card_price{
    font-size: 1rem;
    color: orangered;
} 

.widget_horizontal_card_date{
    font-size:.9rem;
    color: #B8B8B8; 
    font-weight: 600;
    margin: 0;
    border-bottom: 1.5px solid #b8b8b849;
    padding: 0;
}

.widget_horizontal_card_btn {
    width: 100%;
    min-width: 220px;
    background-color: #ff7530;
    color: #fff;
    height: 40px;
    border-radius: 5px;
    outline: none;
    border: none;
}

.widget_horizontal_card_title{
    font-size: 1rem;
    text-align: left;
    font-weight: 600;
    color: #7F7F7F;
    padding-top: 5px;
      white-space: nowrap;
  overflow: hidden;
  overflow-wrap: break-word;
  text-overflow: ellipsis;
  max-width: 200px !important;
    margin: 0;
}

.widget_horizontal_card_price-range {
  margin: 0;
  padding: 0;
  color: #ff7530;
  margin-top: 5px;
  font-size: 24px;
  font-weight: 600;
  border-bottom: 0.5px solid #ccc;
  margin-bottom: 10px;
}




.widget_horizontal_card_div{
transition: all 0.2s ease-in-out;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 10px;
  background-size: contain;
  max-height: 350px;
  max-width: 350px;
  min-height: 140px;
  min-width: 140px;
  height: 100%;
  padding: 10px;
}
.widget_horizontal_card_img{
  max-width: 226px;
  width: 100%;
  height: 100%;
  max-height: 226px;
  min-height: 200px;
  min-width: 140px;
  border-radius: 13px;
  object-fit: contain;
  margin: auto;
  backdrop-filter: blur(4px);
}
.widget_horizontal_card_img_default{
  max-width: 226px;
  width: 100%;
  height: 100%;
  max-height: 226px;
  min-height: 140px;
  min-width: 140px;
  border-radius: 13px;
  object-fit: contain;
  margin: auto;
}

@media (max-width : 1100px){
  .price-range {
    font-size:.8rem;
    margin-bottom: 20px;
}
    .widget_horizontal_card_div{
transition: all 0.2s ease-in-out;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 10px;
  background-size: contain;
  max-height: "";
  max-width: 220px;
  min-height: 140px;
  min-width: 140px;
  height: 100%;
  padding:2px;
}
.widget_horizontal_card_img{
  max-width: 226px;
  width: 100%;
  height: 100%;
  max-height: 226px;
  min-height: 159px;
  min-width: 140px;
  border-radius: 13px;
  object-fit: contain;
  margin: auto;
  backdrop-filter: blur(4px);
}
   
}
