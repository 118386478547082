footer{
    position: relative;
    background: #000;
}
.footer-lines{
    padding: 50px 0;
}
.footer-lines h2{
    margin: 0;
    font-size: 263px;
    color: #fff;
    text-transform: uppercase;
    line-height: 226px;
    white-space: nowrap;
}
.carouselTicker__item,
.carouselTicker__list{border: none !important;}

.quicklink-row{
    padding: 50px 0 100px;
    border-top: 1px solid #1D1E1D;
}
.social-bx{
    display: flex;
    flex-flow: column;
    height: 100%;
    border-right: 1px solid #1D1E1D;
}
.social-bx ul{
    display: flex;
    align-items: center;
    list-style-type: none;
}
.social-bx ul li{
    margin-right: 15px;
}
.social-bx ul li:last-child{
    margin: 0;
}
.social-bx ul li a{
    width: 50px;
    height: 50px;
    line-height: 50px;
    border: 1px solid #fff;
    border-radius: 50%;
    text-align: center;
    font-size: 20px;
    display: block;
    color: #fff;
}
.social-bx ul li a:hover{
    background: #fff;
    color: #000;
}
.quicklinks ul{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    list-style-type: none;
}
.quicklinks ul li{
    margin-right: 50px;
}
.quicklinks ul li:last-child{
    margin: 0;
}
.quicklinks ul li a{
    font-size: 18px;
    font-weight: normal;
    color: #fff;
    display: flex;
    padding: 10px 0;
    text-decoration: none;
    /* color: black; */
}
.quicklinks ul li a:hover{
    color: #F58D53;
}
.quicklinks ul li a.btn-nav{
    font-weight: 500;
    position: relative;
    color: #fff;
    align-items: center;
}
.quicklinks ul li a.btn-nav:after{
    position: absolute;
    content: '';
    width: 100%;
    height: 4px;
    background: linear-gradient(to right, rgba(233,79,67,1) 0%,rgba(245,141,83,1) 100%);
    bottom: 0;
    left: 0;
    transition: 0.4s linear;
    -webkit-transition: 0.4s linear;
}
.quicklinks ul li a.btn-nav svg{
    color: #F58D53;
    margin: 0 0 0 10px;
}
.quicklinks ul li a.btn-nav:hover{
    color: #fff;
}
.quicklinks ul li a.btn-nav:hover:after{
    width: 0;
    left: auto;
    right: 0;
}

.copyright{
    padding: 40px 0 50px;
}
.copyright p{
    margin: 0;
    font-size: 16px;
    font-weight: 300;
    color: #565656;
}
.privacy-links ul{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    list-style-type: none;
}
.privacy-links ul li{
    margin-right: 30px;
    
}
.privacy-links ul li:last-child{
    margin: 0;
}
.privacy-links ul li a{
    font-size: 16px;
    font-weight: 300;
    color: #565656;
    position: relative;
    border-bottom: 1px solid #565656;
    text-decoration: none;
    font-family: "Lexend", sans-serif;
    
}
.privacy-links ul li a:after{
    position: absolute;
    content: '';
    width: 1px;
    height: 100%;
    background: #565656;
    right: -15px;
    top: 0;
}
.privacy-links ul li:last-child a:after{
    display: none;
}
.privacy-links ul li a:hover{
    color: #fff;
    border-bottom: 1px solid #fff;
}
.rfm-marquee-container {
    overflow: hidden !important;
}
  
@media(min-width:1201px) and (max-width:1350px){
    .footer-lines{padding: 30px 0;}
	.footer-lines h2{font-size: 100px;line-height: 100px;}
	.quicklink-row{padding: 30px 0 60px;}
	.quicklinks ul li{margin-right:40px;}
	.quicklinks ul li a{font-size: 15px;justify-content: center;display: table;margin: 0 auto;text-align: center;}
	.social-bx ul li a{width: 40px;height: 40px;line-height: 40px;font-size: 16px;}
	.copyright{padding: 30px 0;}
}

@media(min-width:1025px) and (max-width:1200px){
    .footer-lines{padding: 30px 0;}
	.footer-lines h2{font-size: 100px;line-height: 100px;}
	.quicklink-row{padding: 30px 0 60px;}
	.quicklinks ul li{margin-right:40px;}
	.quicklinks ul li a{font-size: 15px;justify-content: center;display: table;margin: 0 auto;text-align: center;}
	.social-bx ul li a{width: 40px;height: 40px;line-height: 40px;font-size: 16px;}
	.copyright{padding: 30px 0;}
}

@media(min-width:992px) and (max-width:1024px){
    .footer-lines{padding: 30px 0;}
	.footer-lines h2{font-size: 100px;line-height: 100px;}
	.quicklink-row{padding: 30px 0 60px;}
	.quicklinks ul li{margin-right:40px;}
	.quicklinks ul li a{font-size: 15px;justify-content: center;display: table;margin: 0 auto;text-align: center;}
	.social-bx ul li a{width: 40px;height: 40px;line-height: 40px;font-size: 16px;}
	.copyright{padding: 30px 0;}
}

@media(min-width:768px) and (max-width:991px){
    .footer-lines{padding: 30px 0;}
	.footer-lines h2{font-size: 100px;line-height: 100px;}
	.quicklink-row{padding: 30px 0 60px;}
	.quicklinks ul li{margin-right: 20px;}
	.quicklinks ul li a{font-size: 15px;justify-content: center;display: table;margin: 0 auto;text-align: center;}
	.social-bx ul li a{width: 40px;height: 40px;line-height: 40px;font-size: 16px;}
	.copyright{padding: 30px 0;}
	.privacy-links ul li{margin: 0 15px 0 0;}
	.privacy-links ul li a:after{right: -10px;}
	.privacy-links ul li a{font-size: 14px;text-align: center;}
	.copyright p{font-size: 12px;}
}

@media(max-width:767px){
    .footer-lines{padding: 30px 0;}
	.footer-lines h2{font-size: 100px;line-height: 100px;}
	.quicklink-row{padding: 30px 0 60px;}
	.quicklink-row .row{flex-flow: column-reverse;}
	.social-bx ul{justify-content: center;margin: 25px auto 0;}
	.quicklinks ul{flex-flow: column;}
	.quicklinks ul li{margin: 0;width: 100%;}
	.quicklinks ul li a{font-size: 15px;justify-content: center;display: table;margin: 0 auto;text-align: center;}
	.social-bx ul li a{width: 40px;height: 40px;line-height: 40px;font-size: 16px;}
	.copyright{padding: 30px 0;}
	.copyright .row{flex-flow: column-reverse;}
	.privacy-links ul{flex-flow: column;justify-content: center;}
	.privacy-links ul li{margin: 0 0 10px;}
	.privacy-links ul li:last-child{margin: 0;}
	.privacy-links ul li a:after{display: none;}
	.privacy-links ul li a{font-size: 14px;text-align: center;}
	.copyright p{font-size: 14px;text-align: center;margin: 15px auto 0;}
}