.payment_failed_div {
    width: 80%;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: center;
    margin: auto;
}

.payment_failed_details {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
}

.payment_failed_heading {
    font-size: 2rem;
    color: red;
    font-weight: 600;
}

.payment_failed_link {
    text-decoration: none;
    background-color: red;
    color: #fff;
    font-weight: 600;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 20px;
}

.broken_failed {
    width: 45%;
    max-width: 400px;
}

@media (max-width: 700px) {
    .payment_failed_heading {
        font-size: 1.5rem;
    }
}

@media (max-width: 550px) {
    .payment_failed_div {
        width: 100%;
        flex-direction: column-reverse;
    }

    .payment_failed_details {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 20px;
    }

    .payment_failed_heading {
        font-size: 1.5rem;
        text-align: center;
    }

    .broken_failed {
        width: 50%;
    }

}