.payment_method_div{
    width: 100%;
}
.placeorder_btn{
    border:  none;
    outline: none;
   background-color: transparent;
   border : 1px solid #ff7530;
   padding: 2%;
   font-size: 1.2rem;
   color: #ff7530;
   margin-right: 5%;
   cursor: pointer;
   transition: all 0.3s ease-in-out;
   margin-bottom: 5%;
}

.payment_placeorder_btn_div{
    padding-top: 5%;
    background-color: #f1f1f1;
}

.payment_method_radio{
    vertical-align: middle;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1rem;
}

.placeorder_btn:hover{
    background-color: #ff7530;
    color: white;
}
