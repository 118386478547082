 .widget_vertical_card_price{
    font-size: 1rem;
    color: orangered;
} 

.widget_vertical_card_date{
    font-size:.9rem;
    color: #B8B8B8; 
    font-weight: 600;
    margin: 0;
    border-bottom: 1.5px solid #b8b8b849;
    padding: 0;
}

.widget_vertical_card_btn {
    width: 100%;
    max-width: 180px;
    background-color: #ff7530;
    color: #fff;
    height: 40px;
    border-radius: 5px;
    outline: none;
    border: none;
}

.widget_vertical_card_title{
    font-size: 1.4rem;
    text-align: left;
    font-weight: 600;
    color: #7F7F7F;
    padding-top: 5px;
      white-space: nowrap;
  overflow: hidden;
  overflow-wrap: break-word;
  text-overflow: ellipsis;
    margin: 0;
    width: 100%;

}

.widget_vertical_card_price-range {
  margin: 0;
  padding: 0;
  color: #ff7530;
  margin-top: 5px;
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 10px;
}


.widget_vertical_card_text_div {
  height: 100%;
  height: 200px;
  display: flex;
  min-height: 61px;
  width: 70%;
  flex-direction: column;
  justify-content: space-between;
}

.img_div_vertical {
  width: 30%;
}


.widget_vertical_card_div{
transition: all 0.2s ease-in-out;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border-radius: 10px;
  background-size: contain;
  max-height: 220px;
  /* max-width: 350px; */
  min-height: 140px;
  min-width: 140px;
  height: 100%;
  column-gap: 15px;
  padding: 10px;
}
.widget_vertical_card_img{
  max-width: 200px;
  width: 100%;
  height: 100%;
  max-height: 200px;
  min-height: 200px;
  min-width: 200px;
  border-radius: 13px;
  object-fit: contain;
  margin: auto;
  backdrop-filter: blur(4px);
}
.widget_vertical_card_img_default{
  max-width: 200px;
  width: 100%;
  height: 100%;
  max-height: 200px;
  min-height: 200px;
  min-width: 200px;
  border-radius: 13px;
  object-fit: contain;
  margin: auto;
}

@media (max-width: 750px) {
  .widget_vertical_card_div {
    flex-direction: column;
    max-height: fit-content;
  }

  .widget_vertical_card_text_div {
  height: 100%;
  height: 200px;
  display: flex;
  min-height: 61px;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
}

.img_div_vertical {
  width: 100%;
}
}