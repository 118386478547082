.details-wrp {
    padding: 0 0 150px;
}

.details-main {
    background-color: #000;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.details-block {
    padding: 50px 60px 50px 50px;
}

.details-block p {
    font-size: 23px;
    line-height: 24px;
    font-weight: 100;
    color: #fff;
    margin: 0 0 40px;
    font-family: sans-serif; 
}

.details-img img {
    object-fit: cover;
    border-radius: 0 10px 10px 0;
    -webkit-border-radius: 0 10px 10px 0;
    -moz-border-radius: 0 10px 10px 0;
    -ms-border-radius: 0 10px 10px 0;
    -o-border-radius: 0 10px 10px 0;
}


@media (min-width: 1300px) and (max-width: 1399px) {
    .details-wrp { padding: 0 0 110px; }
}

@media (min-width: 1200px) and (max-width: 1299px) {
    .details-wrp { padding: 0 0 80px; }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .details-wrp { padding: 0 0 70px; }
    .details-block { padding: 40px 40px 40px 40px; } 
    .details-block p {margin: 0 0 30px;}
}

@media (min-width: 768px) and (max-width: 991px) {
    .details-wrp { padding: 0 0 70px; }
    .details-block { padding: 30px; } 
    .details-block p {margin: 0 0 30px;}
}

@media (max-width: 767px) {
    .details-wrp { padding: 0 0 50px; }
    .details-block { padding: 30px; } 
    .details-block p {font-size: 16px; line-height: 22px; margin: 0 0 20px;}
    .details-main .row {flex-direction: column-reverse;}
    .details-img img { border-radius: 10px 10px 0 0; -webkit-border-radius: 10px 10px 0 0; -moz-border-radius: 10px 10px 0 0; -ms-border-radius: 10px 10px 0 0; -o-border-radius: 10px 10px 0 0; }
}