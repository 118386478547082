.widget-filter-card-div {
    box-shadow: 0  0 15px 0 rgb(230,230, 230);
    border-radius: 13px;
    padding: 5px 10px 5px 10px;
}


.morePromoters {
    color: #ff7530;
    font-weight: 600;
    cursor: pointer;
    margin-top: 10px;
}


.widget-page-horizontal-view {
    width: 100%;
    overflow-x: scroll;
    display: flex;
    column-gap: 15px;
    height: 430px;
}

.widget-page-vertical-view {
    width: 100%;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    height: fit-content;
    max-height: 600px;
    margin-top: 15px;
    /* max-width: 900px; */
}


.widget_page_main_div {
    width: 100%;
     margin: auto;
     max-width: 1200px;
     display: flex;
     justify-content: space-between;
     column-gap: 20px;
     margin-top: 20px;
     margin-bottom: 0px;
}

.widget-page-horizontal-view::-webkit-scrollbar {
  height: 10px;        
  width: 4px;            
}

.widget-page-horizontal-view::-webkit-scrollbar-thumb {
  background: #ff7530; 
  border-radius: 10px;
}


.widget-page-vertical-view::-webkit-scrollbar {
  height: 10px;        
  width: 10px;            
}

.widget-page-vertical-view::-webkit-scrollbar-thumb {
  background: #ff7530; 
  border-radius: 10px;
}

@media (min-width: 1500px) {
    .widget_page_main_div {
        max-width: 80%;
    }
}