
.backgroundDiv {
  background-image: url("./background-min.png");
  display: flex;
  flex-direction: column;
  text-align: center;
  min-width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
  min-height: 375px;
}
.firstBackgroundDiv {
  position: relative;
  z-index: 2;
  text-align: center;
  margin: auto;
  width: 100%;
  padding-top: 5%;
  max-width: 1000px;
}
.firstBackgroundDiv h1 {
  font-weight: 800;
  text-transform: uppercase;
  font-size: 8vw;
  color: #000;
  letter-spacing: 0.3em;
  text-shadow: 0em 0em 0.3em #fff;
}

.secondBackgroundDiv {
  position: relative;
  z-index: 3;
  text-align: center;
  margin: auto;
  width: 100%;
}
.secondBackgroundDiv h1 {
  margin-top: -5%;
  font-family: "Roboto", Helvetica, Arial, Lucida, sans-serif;
  font-weight: 800;
  text-transform: uppercase;
  font-size: 14vw;
  text-align: center;
  text-shadow: 0em -0.03em 0.15em rgb(0 0 0 / 40%);
  color: white;
  padding-bottom: 5%;
}

.create_event_href_mobile_div{
  display: none;
}

.create_event_href_mobile_outer_div{
  display: none;
}

.create_event_href_mobile{
  display: none;
}

.create_event_href_mobile_helper_text{
  display: none;
}

.info_div {
  box-shadow: 0 0 70px #ccc;
  min-height: 70vh;
}

.pageHeader {
  text-align: center;
}
.pageHeader h1 {
  /* font-family: 'Fjalla One',Helvetica,Arial,Lucida,sans-serif; */
  text-transform: uppercase;
  font-size: 80px;
  line-height: 1.2em;
}
.items_div {
  width: 80%;
  margin: auto;
  /* margin-bottom: 5%; */
  padding-bottom: 5%;
}

.more_div {
  margin: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 50px;
}

.newsletter_form {
  margin: auto;
  display: flex;
  justify-content: center;
}

@media (max-width: 800px) {
  .pageHeader h1 {
    text-transform: uppercase;
    font-size: 40px;
    line-height: 1.2em;
  }
}

@media (max-width: 900px) {
    .backgroundDiv {
        background-position-x: center;
  }
    .firstBackgroundDiv h1{
    /* margin-top: 30px; */
    padding-top: 10%;
  }
  .create_event_href_mobile{
    display: block;
    font-weight: 600;
    color: purple;
    text-decoration: none;
  }

  
  .create_event_href_mobile_helper_text{
    display: block;
    color: purple;
    font-size: 10px;
  }

  .create_event_href_mobile_div{
    display: block;
    border: 1px solid purple;
    border-radius: 5px;
    width: fit-content;
    padding: 5px;
  }

  .create_event_href_mobile_outer_div{
    display: block;
    padding-top: 25px;
  }

}

@media (max-width: 480px) {
  .backgroundDiv {
   background-image: 
    /* linear-gradient(
      142deg,
      rgba(226, 111, 4, 0.363) 0%,
      #3b1e01a6 100%
      ), */
      url("./MTT-mob.png"
      ) !important;
      background-size: contain;

    background-attachment: fixed;
    background-repeat: no-repeat;
    width: 100%;
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding-bottom: 0rem;
    min-height: 253px;
  }
  .firstBackgroundDiv h1{
    /* margin-top: 30px; */
    padding-top: 18%;
  }
  .secondBackgroundDiv h1 {
    margin-top: -7%;
    padding-bottom: 0%;
  }
}

@media (max-width: 390px) {

  .firstBackgroundDiv h1{
    /* margin-top: 30px; */
    padding-top: 22%;
  }
}
