.moon-tower-wrp {
    padding: 150px 0;
    background: #000;
    position: relative;
}

.moon-tower-img {
    padding: 0 30px 0 0;
}

.moon-tower-img img {
    width: 100%;
}

.moon-tower-data {
    padding: 0 0 0 30px;
}

.moon-tower-data > h2 {
    font-size: 96px;
    font-weight: bold;
    color: #fff;
    line-height: 110px;
    margin: 0 0 40px;
    padding: 0 0 40px;
    position: relative;
}

.moon-tower-data > h2:after {
    position: absolute;
    content: '';
    width: 24px;
    height: 4px;
    background: #B948FF;
    bottom: 0;
    left: 0;
}

.moon-tower-data h6 {
    font-size: 18px;
    font-weight: normal;
    color: #fff;
    line-height: 26px;
    margin: 0 0 100px;
}

.moon-tower-accordian .accordion .accordion-item {
    margin: 0 0 40px;
    background-color:transparent;
    border: none;
    padding: 0;
    border-radius: 0;
}

.moon-tower-accordian .accordion .accordion-item .accordion-button {
    font-size: 24px;
    color:#fff;
    font-weight: 700;
    border-radius: 0;
    background-color: #191919;
    position: relative;
    padding: 30px 30px 0;
}

.moon-tower-accordian .accordion .accordion-item .accordion-button span {
    min-width: 30px;
    margin-right: 20px;
}

.moon-tower-accordian .accordion .accordion-item .accordion-button img {     
    margin: 0 auto;
    filter: grayscale(100%);    
    -webkit-filter: grayscale(100%);    
}

.moon-tower-accordian .accordion .accordion-item .accordion-button.collapsed {
    background: transparent;
    color: #939393;
    padding: 0 30px 0;
}

.moon-tower-accordian .accordion .accordion-item .accordion-button:not(.collapsed) {
    background-color:#191919;
    box-shadow: none;
    color: #fff;
    padding: 30px 30px 0;
}

.moon-tower-accordian .accordion .accordion-item .accordion-button:not(.collapsed) img {
    filter: grayscale(0%);
    -webkit-filter: grayscale(0%);
}

.moon-tower-accordian .accordion .accordion-item .accordion-button:focus {
    box-shadow: none;
}

.moon-tower-accordian .accordion .accordion-item .accordion-button::after {
    display: none;
}

.moon-tower-accordian .accordion .accordion-body {
    padding: 20px 30px 30px 80px;
    position: relative;    
    background-color:#191919;
}

.moon-tower-accordian .accordion .accordion-body p {
    margin: 0;
    font-size: 18px;
    font-weight: normal;
    color: #fff;
    line-height: 30px;
    position: relative;
    z-index: 1;
}

.moon-tower-accordian .accordion .accordion-body:after {
    position: absolute;
    content: '';
    width:0px;
    height: 2px;
    bottom: 0;
    left: 0;
    background: -moz-linear-gradient(to right, rgba(43,27,216,1) 0%,rgba(180,70,254,1) 100%);
    background: -webkit-linear-gradient(to right, rgba(43,27,216,1) 0%,rgba(180,70,254,1) 100%);
    background: linear-gradient(to right, rgba(43,27,216,1) 0%,rgba(180,70,254,1) 100%);
    transition: .4s linear;
    -webkit-transition: .4s linear;
    -moz-transition: .4s linear;
    -ms-transition: .4s linear;
    -o-transition: .4s linear;
}

.moon-tower-accordian .accordion-collapse.show .accordion-body:after {
    width: 180px;
}

.moon-tower-wrp .btn-main {
    margin: 116px auto 0;
    justify-content: center;
}

@media(min-width:1201px) and (max-width:1350px) {
    .moon-tower-wrp{padding: 100px 0;}
	.moon-tower-data > h2{font-size: 62px;line-height: 78px;padding: 0 0 20px;margin: 0 auto 20px;}
	.moon-tower-data h6{font-size: 16px;line-height: 26px;margin: 0 auto 60px;}
	.moon-tower-wrp .btn-main{margin: 100px auto 0;}
}

@media(min-width:1025px) and (max-width:1200px) {
    .moon-tower-wrp{padding: 70px 0;}
	.moon-tower-data > h2{font-size: 48px;line-height: 58px;padding: 0 0 20px;margin: 0 auto 20px;}
	.moon-tower-data h6{font-size: 16px;line-height: 26px;margin: 0 auto 60px;}
	.moon-tower-accordian .accordion .accordion-body p{font-size: 14px;line-height: 24px;}
	.moon-tower-accordian .accordion .accordion-item .accordion-button{font-size:18px;}
	.moon-tower-wrp .btn-main{margin: 70px auto 0;}
}

@media(min-width:992px) and (max-width:1024px) {
    .moon-tower-wrp{padding: 70px 0;}
	.moon-tower-img{padding: 0 0 30px;}
	.moon-tower-data{padding: 30px 0 0;}
	.moon-tower-data > h2{font-size: 48px;line-height: 58px;padding: 0 0 20px;margin: 0 auto 20px;}
	.moon-tower-data h6{font-size: 16px;line-height: 26px;margin: 0 auto 60px;}
	.moon-tower-accordian .accordion .accordion-body p{font-size: 14px;line-height: 24px;}
	.moon-tower-accordian .accordion .accordion-item .accordion-button{font-size:18px;}
	.moon-tower-wrp .btn-main{margin: 50px auto 0;}
}

@media(min-width:768px) and (max-width:991px) {
    .moon-tower-wrp{padding: 50px 0;}
	.moon-tower-img{padding: 0 0 30px;}
	.moon-tower-data{padding: 30px 0 0;}
	.moon-tower-data > h2{font-size: 48px;line-height: 58px;padding: 0 0 20px;margin: 0 auto 20px;}
	.moon-tower-data h6{font-size: 16px;line-height: 26px;margin: 0 auto 60px;}
	.moon-tower-accordian .accordion .accordion-body p{font-size: 14px;line-height: 24px;}
	.moon-tower-accordian .accordion .accordion-item .accordion-button{font-size:18px;}
	.moon-tower-wrp .btn-main{margin: 50px auto 0;}
}

@media(max-width:767px) {
    .moon-tower-wrp{padding: 50px 0;}
	.moon-tower-img{padding: 0 0 30px;}
	.moon-tower-data{padding: 30px 0 0;}
	.moon-tower-data > h2{font-size: 30px;line-height: 40px;text-align: center;padding: 0 0 20px;margin: 0 auto 20px;}
	.moon-tower-data > h2:after{margin: 0 auto;right: 0;}
	.moon-tower-data h6{font-size: 16px;line-height: 26px;text-align: center;margin: 0 auto 60px;}
	.moon-tower-accordian .accordion .accordion-body p{font-size: 16px;line-height: 25px;}
	.moon-tower-accordian .accordion .accordion-item .accordion-button{font-size:20px;}
	.moon-tower-wrp .btn-main{margin: 50px auto 0;}
}