.app_navbar {
  display: none;
}

@media (max-width: 900px) {
  .app_navbar {
    display: block;
    width: 100%;
    display: flex;
    flex-direction: row;
    background-color: black;
    justify-content: space-around;
    height: 3rem;
    position: fixed;
    bottom: 0;
    z-index: 999;
  }
  .app_nav_tab {
    display: flex;
    text-decoration: none;
    flex-direction: column;
    font-size: 0.7rem;
    color: white;
    padding-top: 6%;
  }
  .active .app_nav_tab {
    color: orangered;
  }
  .app_nav_tab p {
    text-decoration: none;
  }
  .app_nav_tab:hover {
    color: orangered;
  }
}
