header {
    position: absolute;
    top: 0px;
    left: 0;
    right: 0;
    background: transparent;
    padding: 15px 0;
    z-index: 120;
    transition: 0.4s linear;
    -webkit-transition: 0.4s linear;
    -moz-transition: 0.4s linear;
    -ms-transition: 0.4s linear;
    -o-transition: 0.4s linear;
}

.navbar .container .navbar-brand,
.navbar .container-fluid .navbar-brand {    
    margin-left: 0;
}


.navbar-brand {
    float: none;
    height: auto;
    padding: 0;
    margin: 0;
}

.navbar-brand img {
    max-width: 100%;
    height: 60px;
}

.navbar {
    background: transparent;
    position: relative;
    width: 100%;
    left: 0;
    top: 0;
    border: none;
    border-radius: 0;
    margin: 0;
    padding: 0;
    z-index: 120;
    transition: 0.4s linear;
    -webkit-transition: 0.4s linear;
    height: 70px;
}

.nav-row {
    display: flex;
    flex-flow: column;
    width: 100%;
}

.enter-box {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0 0 15px;
}

.enter-box a {
    font-size: 18px;
    font-weight: normal;
    color: #fff;
    text-transform: uppercase;
    display: flex;
    align-items: center;
}

.enter-box a {
    margin: 0 50px 0 0;
}

.enter-box a:last-child {
    margin-right: 0;
}

.enter-box a img {
    margin: 0 10px 0 0;
}

.enter-box a svg {
    margin-right: 5px;
}

.enter-box a:hover{
    color: #F58D53;
}

.navbar .navbar-nav {
    margin-left: auto;
}

.navbar .navbar-nav .nav-link {
    font-size: 18px;
    font-weight:400;
    color: #fff;
    padding: 10px 0px;
    margin: 0 25px;
    transition: 0.5s linear;
    -webkit-transition: 0.5s linear;
    -moz-transition: 0.5s linear;
    -ms-transition: 0.5s linear;
    -o-transition: 0.5s linear;
}

.navbar .navbar-nav .nav-link:first-child {
    margin-left: 0;
}

.navbar .navbar-nav .nav-link:last-child {
    margin-right: 0;
}

.navbar .navbar-nav .nav-link.btn-nav {
    font-weight: 600;
    position: relative;
}

.navbar .navbar-nav .nav-link.btn-nav:after {
    position: absolute;
    content: '';
    width: 100%;
    height: 4px;
    background: linear-gradient(to right, rgba(233,79,67,1) 0%,rgba(245,141,83,1) 100%);
    bottom: 0;
    left: 0;
    transition: 0.4s linear;
    -webkit-transition: 0.4s linear;
}

.navbar .navbar-nav .nav-link.btn-nav svg {
    font-size: 20px;
    color: #F58D53;
    margin: 0 0 0 5px;
}

.navbar .navbar-nav .nav-link.btn-nav:hover{
    color: #fff;
}

.navbar .navbar-nav .nav-link.btn-nav:hover:after{
    width: 0;
    left: auto;
    right: 0;
}

.navbar .navbar-nav li a:focus,
.navbar .navbar-nav li a:hover,
.navbar .navbar-nav li a:hover {
    color: #F58D53;
}

.navbar .navbar-nav .active a,
.navbar .navbar-nav .active a:focus,
.navbar .navbar-nav .active a:hover {
    color: #F58D53;
}

.navbar .navbar-nav .nav-link:focus,
.navbar .navbar-nav .nav-link:hover {
    color: #F58D53;
}

.navbar .navbar-toggler {
    background-color: transparent;
    outline: none;
    box-shadow: none;
    border: none;
    height: 40px;
    width: auto;
    padding: 0;
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
}

.navbar .navbar-toggler:focus {
    box-shadow: none;
    outline: none; 
}

.navbar .navbar-toggler .navbar-toggler-icon {
    background-image: unset;
}

.navbar-toggler:active, .navbar-toggler:focus { 
    outline: none; 
}

.navbar .navbar-toggler-icon {
    width: 24px;
    height: 18px;
    background-image: none;
    position: relative;
    border-bottom: 2px solid #fff;
    transition: all 300ms linear;
    -webkit-transition: all 300ms linear;
    -moz-transition: all 300ms linear;
    -ms-transition: all 300ms linear;
    -o-transition: all 300ms linear;
}

.navbar .navbar-toggler-icon:after,
.navbar .navbar-toggler-icon:before {
    content: "";
    width: 24px;
    position: absolute;
    height: 2px;
    background-color: #fff;
    top: 0;
    left: 0;
    z-index: 2;
    transition: all 300ms linear;
    -webkit-transition: all 300ms linear;
    -moz-transition: all 300ms linear;
    -ms-transition: all 300ms linear;
    -o-transition: all 300ms linear;
}

.navbar .navbar-toggler-icon:after {
    top: 8px;
}

.navbar-toggler:not(.collapsed) .navbar-toggler-icon:after {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
}

.navbar-toggler:not(.collapsed) .navbar-toggler-icon:before { 
    transform: translateY(8px) rotate(-45deg); 
    -webkit-transform: translateY(8px) rotate(-45deg); 
    -moz-transform: translateY(8px) rotate(-45deg); 
    -ms-transform: translateY(8px) rotate(-45deg); 
    -o-transform: translateY(8px) rotate(-45deg); 
}

.navbar-toggler:not(.collapsed) .navbar-toggler-icon {
    border-color: transparent;
}


@media(min-width:1201px) and (max-width:1350px) {
    .navbar-brand img{height:60px;}
	.navbar .navbar-nav li{margin-right: 50px;}
	.enter-box a,.navbar .navbar-nav .nav-link{font-size: 18px;}
}

@media(min-width:1025px) and (max-width:1200px) {
    .navbar-brand img{height: 40px;}
	.navbar .navbar-nav li{margin-right: 30px;}
	.enter-box a,.navbar .navbar-nav .nav-link{font-size: 16px;}
}

@media(min-width:992px) and (max-width:1024px) {
    .navbar-brand img{height: 40px;}
	.navbar .navbar-nav li{margin-right: 30px;}
	.enter-box a,.navbar .navbar-nav .nav-link{font-size: 16px;}
}

@media(min-width:768px) and (max-width:991px) {
    .navbar-brand img{height: 40px;}
	.navbar .navbar-nav li{margin-right: 30px;}
	.enter-box a,.navbar .navbar-nav .nav-link{font-size: 16px; margin: 0 12px;}
}

@media(max-width:767px) {
    .navbar-brand img { height: 40px;}

    .navbar-collapse { position: fixed; left: -100%; top: 0; bottom: 0; background: #191919; height: 100%; width: 100%; padding: 70px 0 0; transition: all 0.3s linear; -webkit-transition: all 0.3s linear; -moz-transition: all 0.3s linear; -ms-transition: all 0.3s linear; -o-transition: all 0.3s linear; }
	.navbar-collapse.collapse.show{ left: 0; height: 600px; }
	.navbar .navbar-nav{ margin: 0 auto; background: transparent; }
	.nav-row{ flex-flow: column-reverse; align-items: flex-start; justify-content: flex-start; height: 100%; }
	.navbar-brand{ position: relative; z-index: 121; }
	.navbar-toggler{ position: relative; z-index: 121; }
	.navbar .navbar-nav{ align-items: flex-start; padding: 30px 20px; height: 100%; overflow: hidden; overflow-y: auto; display: flex; margin: 0; width: 100%; justify-content: center; }
	.enter-box{padding: 0 20px 30px;margin: 0; }
	.navbar .navbar-nav li{ margin: 0 0 10px; width: 100%; }
	.navbar .navbar-nav li:last-child{ margin: 0; }
	.navbar .navbar-nav .nav-link{font-size: 16px; display: inline-block; margin: 0 0 10px;}
	body.add-fix{ overflow: hidden; }
    .enter-box a { font-size: 14px; margin: 0 20px 0 0; }
}