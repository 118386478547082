* {
  padding: 0;
  margin: 0;
}


body {
  font-family: "IBM Plex Sans", sans-serif;
  color: rgb(70, 70, 70);
  /* background-color: #fff; */
  background: rgba(255,255,255,0.1) !important;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
}

.price-range-event-block {
  font-size: 20px !important;
  color: #B948FF !important;
  font-weight: 600 !important;
  margin-top: 15px !important;
}

/* width */
::-webkit-scrollbar {
  width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.swal-button--cancel {
  background-color: rgb(214, 214, 214) !important;
}
.swal-button{
  background-color: #ff7530;
}

.swal-button--danger {
  background-color: #e64942 !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}




a:hover,
a:focus,
a:active {
    text-decoration: none;
}

ol,
ul {
    margin: 0;
    padding: 0;
}

img {
    display: block;
}

a {
    text-decoration: none;
    transition: 0.5s linear;
    -webkit-transition: 0.5s linear;
    -moz-transition: 0.5s linear;
    -ms-transition: 0.5s linear;
    -o-transition: 0.5s linear;
}

body {
    font-family: "Lexend", sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #000;
    text-decoration: none;
}

.main-wrpper {
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.container {
    max-width: 1310px;
}

/* Common Style Start */
.btn-main {
  position: relative;
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  display: flex;
  align-items: center;
  max-width: 245px;
  width: 100%;    
  transition: .4s linear;
  -webkit-transition: .4s linear;
  -moz-transition: .4s linear;
  -ms-transition: .4s linear;
  -o-transition: .4s linear;
}

.btn-main:after {
  position: absolute;
  content: '';
  width: 25px;
  height: 25px;
  background: #000;
  border-radius: 50%;
  top: 50%;
  left: -12px;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transition: .4s linear;
  -webkit-transition: .4s linear;
  -moz-transition: .4s linear;
  -ms-transition: .4s linear;
  -o-transition: .4s linear;
}

.btn-main span {
  padding: 20px 45px;
  border-radius:5px 0 0 5px;
  border-right: 3px dotted #000;
  display: inline-block;
  background: -moz-linear-gradient(to right, rgba(233,79,67,1) 0%,rgba(245,141,83,1) 100%);
  background: -webkit-linear-gradient(to right, rgba(233,79,67,1) 0%,rgba(245,141,83,1) 100%);
  background: linear-gradient(to right, rgba(233,79,67,1) 0%,rgba(245,141,83,1) 100%);
  transition: .4s linear;
  -webkit-transition: .4s linear;
  -moz-transition: .4s linear;
  -ms-transition: .4s linear;
  -o-transition: .4s linear;
}

.btn-main svg {
  background: #F58D53;
  padding: 25px 10px 24px 10px;
  border-radius: 0 5px 5px 0;
  display: table;
  color: #fff;
  transition: .4s linear;
  -webkit-transition: .4s linear;
  -moz-transition: .4s linear;
  -ms-transition: .4s linear;
  -o-transition: .4s linear;
}

.btn-main:hover svg {
  background: transparent;
  padding: 24px 10px 24px 10px;
  transform: translateX(5px);
  -webkit-transform: translateX(5px);
  -moz-transform: translateX(5px);
  -ms-transform: translateX(5px);
  -o-transform: translateX(5px);
}

.btn-second {
  position: relative;
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  display: flex;
  align-items: center;
  width: 100%;
  transition: .4s linear;
  -webkit-transition: .4s linear;
  -moz-transition: .4s linear;
  -ms-transition: .4s linear;
  -o-transition: .4s linear;
}

.btn-second:after {
  position: absolute;
  content: '';
  width: 25px;
  height: 25px;
  background: #fff;
  border-radius: 50%;
  top: 50%;
  left: -12px;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transition: .4s linear;
  -webkit-transition: .4s linear;
  -moz-transition: .4s linear;
  -ms-transition: .4s linear;
  -o-transition: .4s linear;
}

.btn-second span {
  padding: 20px 45px;
  width: 100%;
  text-align: center;
  border-radius:5px 0 0 5px;
  border-right: 3px dotted #fff;
  display: inline-block;
  background: -moz-linear-gradient(to left, rgba(43,27,216,1) 0%,rgba(180,70,254,1) 100%);
  background: -webkit-linear-gradient(to left, rgba(43,27,216,1) 0%,rgba(180,70,254,1) 100%);
  background: linear-gradient(to left, rgba(43,27,216,1) 0%,rgba(180,70,254,1) 100%);
  transition: .4s linear;
  -webkit-transition: .4s linear;
  -moz-transition: .4s linear;
  -ms-transition: .4s linear;
  -o-transition: .4s linear;
}

.btn-second svg {
  background: #2A1AD8;
  padding: 24px 10px 25px 10px;
  border-radius: 0 5px 5px 0;
  display: table;
  color: #fff;
  transition: .4s linear;
  -webkit-transition: .4s linear;
  -moz-transition: .4s linear;
  -ms-transition: .4s linear;
  -o-transition: .4s linear;
}

.btn-second:hover svg {
  background: transparent;
  color: #000;
  padding: 24px 10px 25px 10px;
  transform: translateX(5px);
  -webkit-transform: translateX(5px);
  -moz-transform: translateX(5px);
  -ms-transform: translateX(5px);
  -o-transform: translateX(5px);
}

@media(min-width:1201px) and (max-width:1350px) {
  .btn-second , .btn-main{font-size: 16px;}
	.btn-second span, .btn-main span{padding: 12px 35px}
	.btn-second:hover svg, .btn-main:hover svg, .btn-second svg, .btn-main svg{padding: 15px 10px;}
}

@media(min-width:1025px) and (max-width:1200px) {
  .btn-second , .btn-main{font-size: 16px;}
	.btn-second span, .btn-main span{padding: 12px 35px}
	.btn-second:hover svg, .btn-main:hover svg, .btn-second svg, .btn-main svg{padding: 15px 10px;}
}

@media(min-width:992px) and (max-width:1024px) {
  .btn-second , .btn-main{font-size: 16px;}
	.btn-second span, .btn-main span{padding: 12px 35px}
	.btn-second:hover svg, .btn-main:hover svg, .btn-second svg, .btn-main svg{padding: 15px 10px;}
}

@media(min-width:768px) and (max-width:991px) {
  .btn-second , .btn-main{font-size: 16px;}
	.btn-second span, .btn-main span{padding: 12px 35px}
	.btn-second:hover svg, .btn-main:hover svg, .btn-second svg, .btn-main svg{padding: 15px 10px;}
}

@media(max-width:767px) {
  .btn-second , .btn-main{font-size: 16px;}
	.btn-second span, .btn-main span{padding: 12px 35px}
	.btn-second:hover svg, .btn-main:hover svg, .btn-second svg, .btn-main svg {padding: 15px 10px;}
}
/* Common Style End */