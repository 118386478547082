.show_page_div {
  padding-top: 2%;
  width: 90%;
  margin: auto;
  padding-bottom: 5%;
  min-height: 60vh;
  background-color: #f1f1f1 !important;
}

.description_div {
  padding-top: 50px;
  width: 100%;
  margin: auto;
  max-width: 1000px;
}


.heading_break {
  width: 20%;
  height: 1px;
  background-color: #ff7530;
  margin-bottom: 10px;
  margin-top: 1%;
}

.show_details_break {
  margin-top:20px;
  width: 100%;
  height: 1px;
  background-color: #cccc;
  margin-bottom: 16px;
}
.description_div_navbar {
  width: 30%;
  display: flex;
  justify-content: space-evenly;
  border-top-left-radius: 13px;
  margin-left: 5px;
}

.description_div_navbar_tab {
  border: 1px solid #ccc;
  margin-top: 10px;
  margin-left:  5px;
  background-color: rgb(230, 230, 230);
  width: 50%;
  text-align: center;
  color: #ff7530;
  padding: 1%;
  border-top-left-radius: 13px;
  border-top-right-radius: 13px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.description_div_navbar_tab:hover {
  background-color: #ccc;
  color: black;
  transition: all 0.4s ease-in-out;
  cursor: pointer;
}

.item_card_img_div{
  display: flex;
  justify-content: center;
  width: 50%;
  max-width: 300px;
  max-height: 300px;
  position: relative;
  box-shadow: 0 0 10px #ccc;
  border-radius: 13px;
  overflow: clip;
}


.description_div_Div {
  padding-top: 20px;
  width: 98%;
  margin: auto;
  background-color: #fff;
  border-bottom-left-radius: 13px;
  border: 1px solid #ccc;
  min-height: 50px;
  text-align: justify;
  margin-top: -1px;
  padding-left: 3%;
  padding-right: 2%;
  padding-bottom: 1%;
  transition: all .6s ease-in-out;
}

.about_artist {
  font-size: 1rem;
  font-weight: 500;
}

.description span {
  color: orangered;
  cursor: pointer;
}
.show_date {
  font-size: 22px;
  font-weight: 600;
  color: #ff7530;
  margin-top: 6px;
  margin-bottom: 6px;
}

.show_venue {
  font-size: 18px;
  font-weight: 600;
  color: black;
  margin-top: 6px;
  margin-bottom: 6px;
  vertical-align: middle;
}

.show_ticket_price_detail {
  font-size: 0.8rem;
  color: grey;
  align-items: center;
  vertical-align: middle;
}

.show_artist {
  font-size: 1.01rem;
  font-weight: 600;
  margin-bottom: -12px;
}

.show_flex_div_1 {
  display: flex;
  justify-content: space-between;
  max-width: 1000px;
  margin: auto;
}
.show_details_price_buy {
  margin-left: 5%;
  width: 70%;
}

.show_time {
  margin-top: 2px;
  margin-bottom: 2px;
}

.show_name {
  font-size: 2rem;
  font-weight: 700;
}
.item_card_img_show_card {
  max-width: 300px;
  width: 100%;
  height: 300px;
  border-radius: 13px;
  object-fit: contain;
  backdrop-filter: blur(4px);
}
.show_ticket_price {
  color: #ff7530;
  font-size: 2rem;
  font-weight: bold;
}
.description {
  text-align: justify;
  font-size: 0.8rem;
  font-weight: 600;
  color: rgb(112, 112, 112);
  margin: auto;
  margin-bottom: 1%;
  margin-top: 2%;
}
@media (max-width: 900px) {
  .item_card_img_div{
  margin: auto;
}
  .show_flex_div_1 {
    display: flex;
    flex-direction: column;
  }
  .heading_break {
    width: 50%;
    height: 1px;
    margin: auto;
    background-color: #ff7530;
    margin-bottom: 10px;
    margin-top: 1%;
  }
  .description_div_navbar {
    width: 50%;
  }
  .item_card_img_show_card {
   max-width: 300px;
  width: 100%;
  height: 300px;
  border-radius: 13px;
  object-fit: contain;
  }
  .show_name {
    font-size: 48px;
    text-align: center;
  }
  .show_date {
    font-size: 32px;
  }
  .show_time{
      font-size: 28px;
  }
  .show_venue{
      font-size: 28px;
  }
  .show_ticket_price{
      font-size: 45px;
  }
  .show_ticket_price_detail{
      font-size: 20px;
  }
  .show_details_price_buy {
    margin-left: 2%;
    width: 100%;
    margin: auto;
  }
}

@media (max-width: 500px) {

  .item_card_img_div{
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 300px;
  max-height: 300px;
  position: relative;
  box-shadow: 0 0 10px #ccc;
  border-radius: 13px;
  overflow: clip;
}

  .heading_break {
    width: 50%;
    height: 1px;
    margin: auto;
    background-color: #ff7530;
    margin-bottom: 10px;
    margin-top: 1%;
  }
  .show_ticket_price_detail {
    font-size: 18px;
  }

.show_time{
    font-size: 18px;
}
.show_venue{
    font-size: 18px;
}

  .show_name {
    font-size: 36px;
    text-align: center;
  }

.show_date{
    font-size: 18px;
}
  .item_card_img_show_card {
  max-width: 500px;
  width: 100%;
  height: 300px;
  border-radius: 13px;
  object-fit: contain;
  }
  .description_div_navbar {
    width: 100.4%;
  }

  .show_ticket_price {
    color: #ff7530;
    font-size: 1.85rem;
    font-weight: bold;
  }

  .show_ticket_price_detail {
    font-size: 0.8rem;
    color: grey;
    align-items: center;
    vertical-align: middle;
  }

}

@media (max-width: 360px){
  .description_div_navbar {
    padding-top: 10px;
    width: 100%;
  }
}

@media (max-width : 320px){
  .item_card_img_show_card {
   max-width: 280px;
  width: 100%;
  height: 280px;
  border-radius: 13px;
  object-fit: contain;
  }

    .show_name {
    font-size: 32px;
    text-align: center;
  }
}
