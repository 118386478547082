.ticket_navbar {
  min-width:300px;
  width: 50%;
  display: flex;
  justify-content: space-between;
}

.ticket_navbar_links {
  text-align: center;
  width: 100%;
  padding-top: 4%;
  padding-bottom: 4%;
  background-color: #ccc;
  color: black;
  border-top-left-radius: 13px;
  border-top-right-radius: 13px;
}
.active .ticket_navbar_links{
  background-color: #FF7530;
  color: white;
}

.ticket_navlinks {
  text-decoration: none;
  color: black;
  width: 100%;
}

@media (max-width:900px){
  .ticket_navbar {
    width: 50%;
    min-width:280px;
    display: flex;
    justify-content: space-between;
  }
}
