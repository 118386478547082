 .show_card_price{
    font-size: 1rem;
    color: orangered;
} 

.show_card_date{
    font-size:.9rem;
    color: #B8B8B8; 
    font-weight: 600;
    margin: 0;
    border-bottom: 1.5px solid #b8b8b849;
    padding: 0;
}

.show_card_title{
    font-size: 1rem;
    text-align: left;
    font-weight: 600;
    color: black;
    padding-top: 5px;
      white-space: nowrap;
  overflow: hidden;
  overflow-wrap: break-word;
  text-overflow: ellipsis;
  max-width: 200px !important;
    margin: 0;
}

.show_card_text_div{
    background-color: #f1f1f1;
}

.price-range {
  margin: 0;
  padding: 0;
  color: #ff7530;
  margin-top: 5px;
  font-weight: 600;
}

.show_card_div{
transition: all 0.2s ease-in-out;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 10px;
  background-size: contain;
  max-height: 280px;
  max-width: 280px;
  min-height: 140px;
  min-width: 140px;
  height: 100%;
  padding: 10px;
}
.show_card_img{
  max-width: 226px;
  width: 100%;
  height: 100%;
  max-height: 226px;
  min-height: 200px;
  min-width: 140px;
  border-radius: 13px;
  object-fit: contain;
  margin: auto;
  backdrop-filter: blur(4px);
}
.show_card_img_default{
  max-width: 226px;
  width: 100%;
  height: 100%;
  max-height: 226px;
  min-height: 140px;
  min-width: 140px;
  border-radius: 13px;
  object-fit: contain;
  margin: auto;
}

@media (max-width : 1100px){
  .price-range {
    font-size:.8rem;
    margin-bottom: 20px;
}
    .show_card_div{
transition: all 0.2s ease-in-out;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 10px;
  background-size: contain;
  max-height: 220px;
  max-width: 220px;
  min-height: 140px;
  min-width: 140px;
  height: 100%;
  padding:2px;
}
.show_card_img{
  max-width: 226px;
  width: 100%;
  height: 100%;
  max-height: 226px;
  min-height: 159px;
  min-width: 140px;
  border-radius: 13px;
  object-fit: contain;
  margin: auto;
  backdrop-filter: blur(4px);
}
   
}

@media (max-width : 430px){

  .show_card_date{
    font-size:.7rem;
    margin-top: 5px;
}

.price-range {
    font-size:.7rem;
        margin-bottom: 0px;
}

.show_card_title{
    font-size: 0.8rem;
    text-align: left;
    font-weight: 600;
    color: black;
    padding-top: 10px !important;
  max-width: 180px !important;
    margin: 0;
}
  
}


@media (max-width : 390px){

.show_card_title{

  max-width: 160px !important;

}
  
}