
/* **** Current Events **** */
.current-event-wrp{
    padding: 150px 0;
}
.current-title{
    padding: 0 0 50px;
    margin: 0 0 50px;
    position: relative;
    border-bottom: 1px solid rgba(29,30,29,0.20);
}

/* */


.current-title .row{
    align-items: flex-end;
}
.current-title h2{
    font-size: 96px;
    font-weight: bold;
    letter-spacing: -3px;
    color: #000;
    margin: 0;
}
.current-title p{
    font-size: 18px;
    font-weight: normal;
    color: #000;
    line-height:30px;
    margin: 0;
}

.event-slider-row .container-fluid{
    width: calc(1380px - 24px + (100vw - 1380px)/2);
    margin-right: 0;
    padding: 0;
}
.event-block{
    padding: 0 40px;
    border-right: 1px solid rgba(29,30,29,0.20);
}
.event-block h6{
    text-align: left;
    font-size: 16px;
    position: relative;
    font-weight: 500;
    /* color: #B948FF; */
    color: #736C6C;
    margin: 0 0 10px;
    padding: 0 0 20px;
}
.event-block h3{
    font-size: 24px;
    font-weight: 500;
    color: #000000;
    line-height:30px;
    padding: 0 0 10px;
}
.event-block h6:after{
    position: absolute;
    content: '';
    width: 24px;
    height: 4px;
    background: #B948FF;
    left: 0;
    bottom: 0;
}
.event-block p{
    font-size: 18px;
    font-weight: 300;
    color: #000;
    line-height: 26px;
    margin: 0 0 40px;
}
.event-img{
    margin: 0 0 40px;
}
.event-img img{
    width: 100%;
    height: 250px;
    object-fit: cover;
    object-position: top;
}

.event-slider .slick-prev,
.event-slider .slick-next {
    position: absolute;
    right: 20%;
    left: auto;
    border: none;
    color: transparent;
    background-color: transparent;   
    width: auto;
    height: auto;
    font-size: 0;
    color: transparent;
    z-index: 5;
    top:-110px;
    -webkit-transition: .4s linear;
    -moz-transition: .4s linear;
    -ms-transition: .4s linear;
    transition: .4s linear;
}
.event-slider .slick-prev {
    right: 23%;
}
.event-slider .slick-prev i,
.event-slider .slick-prev svg,
.event-slider .slick-next i,
.event-slider .slick-next svg {
    color: rgba(56, 56, 56, 0.40);
    font-weight: 400;
    line-height: 30px;
    display: block;
    border-radius: 50%;
    font-size: 20px;
    width: 20px;
    height: 20px;
    transition: .4s linear;
    -webkit-transition: .4s linear;
}
.event-slider .slick-prev i:hover,
.event-slider .slick-prev svg:hover,
.event-slider .slick-next i:hover,
.event-slider .slick-next svg:hover {
    color: #000000 !important;
}
.event-slider .slick-prev:before, .event-slider .slick-next:before {
    content: none;
}
/* **** End Current Events **** */


@media(min-width:1351px) and (max-width:1850px){
	.event-block h3{font-size: 22px;}
	.event-slider .slick-prev, .event-slider .slick-next{right:15px;top: -100px;}
	.event-slider .slick-prev{right: 50px;}
}

@media(min-width:1201px) and (max-width:1350px){
    .current-event-wrp{padding: 100px 0;}
	.current-title{padding: 0 0 30px;margin: 0 0 30px;}
	.current-title h2{font-size: 62px;line-height: 78px;}
	/* .current-title p{font-size: 30px;line-height: 30px;} */
	.event-block{padding: 0 20px;}
	.event-slider-row .container-fluid{width: calc(1140px - 24px + (100vw - 1140px)/2);}
	.event-block h6{font-size: 15px;}
	.event-block p{font-size: 14px;line-height: 22px;margin: 0 0 25px;}
	.event-block h3{font-size: 18px;line-height: 26px;}
	.event-img img{height: 200px;}
	.event-img{margin: 0 0 25px;position: relative;z-index: 5;}
	.event-slider .slick-prev, .event-slider .slick-next{right:15px;top: -70px;}
	.event-slider .slick-prev{right: 50px;}
}

@media(min-width:1025px) and (max-width:1200px){
    .current-event-wrp{padding: 70px 0;}
	.current-title{padding: 0 0 30px;margin: 0 0 30px;}
	.current-title h2{font-size: 48px;line-height: 58px;}
	.current-title p{font-size: 15px;line-height: 22px;}
	.event-block{padding: 0 20px;}
	.event-slider-row .container-fluid{width: 100%;padding: 0 15px;}
	.event-block h6{font-size: 15px;}
	.event-block p{font-size: 14px;line-height: 22px;margin: 0 0 25px;}
	.event-block h3{font-size: 18px;line-height: 26px;}
	.event-img img{height: 200px;}
	.event-img{margin: 0 0 25px;position: relative;z-index: 5;}
	.event-slider .slick-prev, .event-slider .slick-next{right:15px;top: -70px;}
	.event-slider .slick-prev{right: 50px;}
}

@media(min-width:992px) and (max-width:1024px){
    .current-event-wrp{padding: 70px 0;}
	.current-title{padding: 0 0 30px;margin: 0 0 30px;}
	.current-title h2{font-size: 48px;line-height: 58px;}
	.current-title p{font-size: 15px;line-height: 22px;}
	.event-block{padding: 0 20px;}
	.event-slider-row .container-fluid{width: 100%;padding: 0 15px;}
	.event-block h6{font-size: 15px;}
	.event-block p{font-size: 14px;line-height: 22px;margin: 0 0 25px;}
	.event-block h3{font-size: 18px;line-height: 26px;}
	.event-img img{height: 200px;}
	.event-img{margin: 0 0 25px;position: relative;z-index: 5;}
	.event-slider .slick-prev, .event-slider .slick-next{right:15px;top: -70px;}
	.event-slider .slick-prev{right: 50px;}
}

@media(min-width:768px) and (max-width:991px){
    .current-event-wrp{padding: 50px 0;}
	.current-title{padding: 0 0 30px;margin: 0 0 30px;}
	.current-title h2{font-size: 48px;line-height: 58px;}
	.current-title p{font-size: 15px;line-height: 22px;}
	.event-block{padding: 0 20px;}
	.event-slider-row .container-fluid{width: 100%;padding: 0 15px;}
	.event-block h6{font-size: 15px;}
	.event-block p{font-size: 14px;line-height: 22px;margin: 0 0 25px;}
	.event-block h3{font-size: 16px;line-height: 24px;}
	.event-img img{height: 200px;}
	.event-img{margin: 0 0 25px;position: relative;z-index: 5;}
	.event-slider .slick-prev, .event-slider .slick-next{right:15px;top: -70px;}
	.event-slider .slick-prev{right: 50px;}
}

@media(max-width:767px){
    .current-event-wrp{padding: 50px 0;}
	.current-title{margin: 0 0 20px;padding: 0 0 20px;}
	.current-title h2{font-size: 32px;text-align: center;margin: 0 auto 25px;}
	.current-title p{font-size: 15px;line-height: 24px;text-align: center;}
	.event-slider-row .container-fluid{width: 100%;padding: 0 15px;}
	.event-block{padding: 0 20px;border: none;}
	.event-block h6{font-size: 15px;}
	.event-block p{font-size: 15px;line-height: 22px;margin: 0 0 25px;}
	.event-block h3{font-size: 20px;line-height: 28px;}
	.event-img{margin: 0 0 25px;position: relative;z-index: 5;}
	.event-slider .slick-prev, .event-slider .slick-next{top: 50%;transform: translateY(-50%);right:-10px;}
	.event-slider .slick-prev{right: auto;left:-10px;}
	/* .event-slider .slick-prev:before, .event-slider .slick-next:before{background: #2A1AD8;border-radius: 0 15px 15px 0;font-size: 14px;color: #fff;height:35px;line-height: 35px;} */
	/* .event-slider .slick-prev:before{border-radius: 15px 0 0 15px;} */
	/* .event-slider .slick-prev:before:hover, .event-slider .slick-next:before:hover{color: #fff;} */
    .event-slider .slick-prev i, .event-slider .slick-prev svg, .event-slider .slick-next i, .event-slider .slick-next svg {
        background: #2A1AD8;
        font-size: 14px;
        line-height: 100%;
        color: #fff;
        width: 14px;
        height: 14px;
        padding: 8px;
        border-radius: 0 15px 15px 0;
        -webkit-border-radius: 0 15px 15px 0;
        -moz-border-radius: 0 15px 15px 0;
        -ms-border-radius: 0 15px 15px 0;
        -o-border-radius: 0 15px 15px 0;
    }
    .event-slider .slick-prev i, .event-slider .slick-prev svg {
        border-radius: 15px 0 0 15px;
        -webkit-border-radius: 15px 0 0 15px;
        -moz-border-radius: 15px 0 0 15px;
        -ms-border-radius: 15px 0 0 15px;
        -o-border-radius: 15px 0 0 15px;
    }
    .event-slider .slick-prev i:hover,
    .event-slider .slick-prev svg:hover,
    .event-slider .slick-next i:hover,
    .event-slider .slick-next svg:hover {
        color: #fff !important;
    }
}