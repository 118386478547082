.backgroundDiv2 {
  background-image: 
  /* linear-gradient(
      142deg,
      rgba(226, 111, 4, 0.363) 0%,
      #3b1e01a6 100%
    ), */
    url("./background2.png");
  display: flex;
  flex-direction: column;
  text-align: center;
  min-width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
  justify-items: center;
  min-height: 375px;
}

.register_btn_helper_text_pd2{
  display: none;
  color: black;
    font-size: 10px;
    font-weight: 800;
}

.create_event_div{
  width: 80%;
  margin: auto;
  display: flex;
  margin-top: 20px;
  flex-direction: column;
  justify-content: center;
  max-width: 600px;
}

.firstBackgroundDiv2 {
  position: relative;
  z-index: 2;
  text-align: center;
  margin: auto;
  width:100%;
  max-width:  1000px;
  padding-top: 4%;
}

.create_event_btn{
  display: none;
}

.firstBackgroundDiv2 h1 {
  font-weight: 800;
  text-transform: uppercase;
  /* font-size: 100px; */
  font-size: 7vw;
  color: #000;
  letter-spacing: 0.3em;
  text-shadow: 0em 0em 0.3em #fff;
  margin-top: 15px;
}

.secondBackgroundDiv2 {
  position: relative;
  z-index: 3;
  text-align: center;
  margin: auto;
  width: 100%;
}
.secondBackgroundDiv2 h1 {
  margin-top: -5%;
  font-family: "Roboto", Helvetica, Arial, Lucida, sans-serif;
  font-weight: 800;
  text-transform: uppercase;
  font-size: 8vw;
  text-align: center;
  text-shadow: 0em -0.03em 0.15em rgb(0 0 0 / 40%);
  color: white;
  padding-bottom: 5%;
}

.my_account_forms_div {
  display: flex;
  width: 90%;
  max-width: 1000px;
  margin: auto;
  justify-content: space-evenly;
  padding-bottom: 5%;
}

@media (max-width: 900px){

  .backgroundDiv2 {
        background-position-x: center;
  }


  .create_event_btn{
    margin: auto;
    display: block;
    border:  none;
    outline: none;
   background-color: transparent;
   border : 1px solid #ff7530;
   padding: 3%;
   font-size: 1.2rem;
   color: #ff7530;
   cursor: pointer;
   transition: all 0.3s ease-in-out;
   margin-bottom: 16px;
   text-align: center;
   text-decoration: none;
   width: 90%;
   margin-top: 5px;
}

.register_btn_helper_text_pd2{
  margin-left: 2%;
  display: block;
}

.create_event_btn:hover{
    background-color: #ff7530;
    color: white;
}

}

@media (max-width: 800px) {
  .my_account_forms_div {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: auto;
  }
  .my_account_forms_div {
    display: flex;
    width: 80%;
    margin: auto;
    padding-bottom: 15%;
  }

  
.firstBackgroundDiv2 h1 {
  font-weight: 800;
  text-transform: uppercase;
  font-size: 8vw;
  color: #000;
  letter-spacing: 0.3em;
  text-shadow: 0em 0em 0.3em #ffff;
  margin-top: 15px;
}

}

@media (max-width: 480px) {
  .backgroundDiv2 {
    background-image: 
    /* linear-gradient(
      142deg,
      rgba(226, 111, 4, 0.363) 0%,
      #3b1e01a6 100%
      ), */
      url("./MTT-mob.png"
      ) !important;
    background-size: contain;
    background-attachment: fixed;
    background-repeat: no-repeat;
    width: 100%;
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding-bottom: 0rem;
    min-height: 253px;
  }

  .firstBackgroundDiv2 h1 {
     padding-top: 10% !important; 
     font-size: 8vw;
     
  }

  .my_account_forms_div {
    display: flex;
    width: 80%;
    margin: auto;
    padding-bottom: 20%;
  }
}


@media (max-width: 390px) {

  .firstBackgroundDiv2 h1{
    /* margin-top: 30px; */
    padding-top: 22%;
  }
}
