.custom-calendar-cell {
    height: 200px; /* Adjust the height as needed */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0px;
    overflow-y: auto;
    padding-bottom: 50px;
}

.rs-calendar-table-cell-content {
    height: 180px !important;
}

.rs-btn-sm {
    color: #fff;
    background-color: #ff7530;
}

.rs-calendar-table-cell-day {
    font-size: 1rem;
    text-align: center;
    font-weight: 600;
       color: #7F7F7F;

    text-align: left !important;
}

.rs-calendar-panel .rs-calendar-table-cell-is-today .rs-calendar-table-cell-day {
    width: 30px !important;
    height: 30px !important;
    display: flex;
    justify-content: center;
    margin-left: 0;
    align-items: center;
}

.custom-calendar-cell span {
    font-size: 10px;
        color: #7F7F7F;
}

.custom-calendar-cell p {
    font-size: 14px;
    text-align: left;
    border-left: 2px solid #F54C16;
    padding-left: 5px;
}




.custom-calendar-cell > div {
    margin-bottom: 5px; /* Space between events */
}

.custom-calendar-cell img {
    border-radius: 5px;
    object-fit: cover;
}

.custom-calendar-cell p {
    margin: 0;
    font-size: 0.8em; /* Adjust text size to fit more text */
}

.custom-calendar-cell strong {
    padding: 0;
    margin: 0;
}