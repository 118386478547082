.delete_modal_p{
    margin-top: 10px;
    font-size: 14px;
}

.delete_modal_confirm_p{
    font-size: 16px;
    margin-bottom: 5px ;
    margin-top: 5px ;
    font-weight: bold;
}

.orange_span{
    font-size: 16px;
    color: #ff7530;
    font-weight: 800;
}

.delete_modal_btn_flex{
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.cancel_btn{
    background-color: rgb(232, 232, 232);
    margin-top: 15px;
    border:  none;
    outline: none;
    width: 45%;
    padding-top: 3%;
    padding-bottom: 3%;
    padding-right: 6%;
    padding-left: 6%;
   font-size: 0.9rem;
   border-radius: 8px;
   color: rgb(91, 90, 90);
   margin-right: 5%;
   cursor: pointer;
   transition: all 0.3s ease-in-out;
   margin-bottom: 16px;
}

.delete_btn{
    background-color: #ff7530;
    margin-top: 15px;
    border:  none;
    outline: none;
    padding-top: 3%;
    padding-bottom: 3%;
    padding-right: 6%;
    padding-left: 6%;
   font-size: 0.9rem;
   border-radius: 8px;
   color: rgb(255, 255, 255);
   margin-right: 5%;
    width: 45%;
   cursor: pointer;
   transition: all 0.3s ease-in-out;
   margin-bottom: 16px;
}

.disabled_delete_btn{
    background-color: #f59f75;
    margin-top: 15px;
    border:  none;
    outline: none;
    padding-top: 3%;
    padding-bottom: 3%;
    padding-right: 6%;
    padding-left: 6%;
   font-size: 0.9rem;
   border-radius: 8px;
   color: rgb(255, 255, 255);
   margin-right: 5%;
    width: 45%;
   cursor: not-allowed;
   transition: all 0.3s ease-in-out;
   margin-bottom: 16px;
}

.circular_spinner_div{
    display: flex;
    justify-content: center;
    align-items: center;
}